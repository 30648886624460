import React from 'react'

import { VStack } from '@chakra-ui/react'

import { WandaSpinner } from '@wanda-space/noelle'
import { useOpsItems } from '@wanda/shared'
import { useListing } from '@wanda/shared/src/hooks/useListing'

import { ItemCard } from 'components/ItemCard/ItemCard'
import { prop } from 'ramda'
import { ListingImages } from './ListingImages'
import { ListingInformation } from './ListingInformation'

export const ListingDetails = ({ listingId }: { listingId: string }) => {
  const listing = useListing({ listingId: listingId }, { enabled: !!listingId })
  const itemIds = listing.data?.storageItems.map(prop('id')) ?? []

  const itemsQuery = useOpsItems({ filters: { id: itemIds } }, { enabled: !!itemIds.length })

  return (
    <VStack alignItems="initial">
      {listing.isInitialLoading ? <WandaSpinner /> : null}
      {listing.data ? (
        <>
          <ListingInformation listing={listing.data} />
          <ListingImages listingId={listingId} sellerAccountId={listing.data.sellerAccountId} />
          {itemsQuery.data?.map((item) => (
            <ItemCard key={item.id} item={item} />
          ))}
        </>
      ) : null}
    </VStack>
  )
}
