import { Box, type BoxProps, type ThemingProps, useStyleConfig } from '@chakra-ui/react'
import React, { type ReactNode } from 'react'

interface CardProps extends BoxProps {
  variant?: ThemingProps<'Card'>['variant']
  children: ReactNode
}

export function Card(props: CardProps) {
  const { variant, ...rest } = props

  const styles = useStyleConfig('Card', { variant })

  return <Box __css={styles} {...rest} />
}
