import { type PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import type { ItemDto } from '@wanda-space/ops-types'
import type { WarehouseLocation } from '@wanda/shared/src/api-client/routes/warehouses'
import { uniq } from 'ramda'

import type { RootState } from 'store'

export interface ItemSubset extends Pick<ItemDto, 'id' | 'simpleId' | 'name'> {}
export interface MoveEvent {
  itemId: string
  location: WarehouseLocation
  previousLocation?: WarehouseLocation
  date: number
}
export interface ItemLookupHistoryState {
  items: ItemSubset[]
  recentLocations: Record<string, WarehouseLocation[]>
  moveEvents: MoveEvent[]
}

export const initialState: ItemLookupHistoryState = {
  items: [],
  recentLocations: {},
  moveEvents: [],
}

const slice = createSlice({
  name: 'itemLookupHistory',
  initialState,

  reducers: {
    addItem: (state, { payload }: PayloadAction<ItemSubset>) => {
      const items = [...state.items]
      items.unshift(payload)
      state.items = uniq(items).slice(0, 50)
    },
    addLocation: (state, { payload }: PayloadAction<WarehouseLocation>) => {
      const locations = state.recentLocations[payload.warehouse] || []
      locations.unshift(payload)
      state.recentLocations[payload.warehouse] = uniq(locations).slice(0, 10)
    },
    addMoveEvent: (state, { payload }: PayloadAction<MoveEvent>) => {
      const updates = [...state.moveEvents]
      updates.unshift(payload)
      state.moveEvents = uniq(updates)
    },
    removeMoveEvent: (state, { payload }: PayloadAction<MoveEvent>) => {
      const updates = [...state.moveEvents]
      state.moveEvents = updates.filter(
        (event) => event.itemId !== payload.itemId && event.location.id !== payload.location.id,
      )
    },
    clearMoveEvents: (state) => {
      state.moveEvents = []
    },
  },
})

export const { addItem, addLocation, addMoveEvent, removeMoveEvent, clearMoveEvents } =
  slice.actions
export default slice.reducer

export const selectRecentLocations = createSelector(
  [
    (state: RootState): ItemLookupHistoryState => state.itemLookupHistory,
    (state: RootState): string | undefined => state.filters.warehouse,
  ],
  (itemLookupHistory, warehouseId): WarehouseLocation[] => {
    const { recentLocations } = itemLookupHistory
    return warehouseId && warehouseId !== 'all' ? recentLocations[warehouseId] ?? [] : []
  },
)
