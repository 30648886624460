import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { validate } from 'uuid'

import { type ServiceProvider, fetchServiceProvider } from '../api-client/routes/service-providers'

export function useServiceProvider(
  id: string | undefined | null,
  options?: { refetchOnMount?: boolean },
): ServiceProvider | undefined {
  const { getAccessTokenSilently } = useAuth0()
  const query = useQuery(
    ['service-provider', id],
    async (): Promise<ServiceProvider> => {
      const token = await getAccessTokenSilently()
      return fetchServiceProvider(id!, token)
    },
    { ...options, enabled: !!id && validate(id), suspense: true },
  )
  return query.data
}
