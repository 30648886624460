import { useAuth0 } from '@auth0/auth0-react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { StorageItemState } from '@wanda-space/types'
import { UserRole, WarehouseLocationSelect, requireRoles, useItems } from '@wanda/shared'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { updateItemLocation } from 'api/routes/items'
import type { WarehouseLocation } from 'api/routes/warehouse'
import { Header } from 'components/Header'
import { ItemCard } from 'components/ItemCard/ItemCard'
import { ItemDetailTabs } from 'components/ItemDetailTabs'
import { ItemScanner } from 'components/ItemScanner'
import { WarehouseLocationButtons } from 'components/WarehouseLocationButtons/WarehouseLocationButtons'
import WarehouseSelector from 'components/WarehouseSelector/WarehouseSelector'
import Loader from 'components/ui/Loader'
import { useAppSelector } from 'hooks/useAppSelector'
import { useItem } from 'hooks/useItem'
import { setWarehouse } from 'store/filters'
import { addItem, addLocation } from 'store/itemLookupHistory'
import { toItemSubset } from 'store/mappers'

const ItemLocationVinden = requireRoles(
  [
    UserRole.ADMIN,
    UserRole.TECH,
    UserRole.OPS_ADMIN,
    UserRole.OPS_OPERATOR,
    UserRole.OPS_WAREHOUSE,
  ],
  () => {
    const { getAccessTokenSilently } = useAuth0()
    const params = useParams<{ warehouseId?: string; itemId?: string }>()
    const toast = useToast()
    const filters = useAppSelector((state) => state.filters)
    const warehouseId = filters.warehouse === 'all' ? undefined : filters.warehouse

    useEffect(() => {
      if (params.warehouseId && params.warehouseId !== warehouseId) {
        dispatch(setWarehouse(params.warehouseId))
      }
    }, [warehouseId, params.warehouseId])

    const [search, setSearch] = useState<string | undefined>()
    const [showDetails, setShowDetails] = useState(false)
    const items = useItems(
      { search, orderBy: 'createdAt', orderDir: 'ASC', itemsPerPage: 2, page: 0, filters: {} },
      { enabled: !!warehouseId && !!search },
    )
    const item = useItem(items.data?.items[0]?.id, { suspense: false })
    const itemId = item.data?.id
    const dispatch = useDispatch()

    const [error, setError] = useState<Error | null>()
    const onScan = useCallback(setSearch, [setSearch])
    const [location, setLocation] = useState<WarehouseLocation | null>()
    const queryClient = useQueryClient()
    const mutation = useMutation(
      async (props: { itemId: string; location: string }) => {
        const token = await getAccessTokenSilently()

        return updateItemLocation({ ...props, token })
      },
      {
        mutationKey: ['location'],
        onSuccess: () => {
          toast({
            title: 'Item location updated',
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
          queryClient.invalidateQueries(['item'])
          queryClient.invalidateQueries(['items'])
        },
        onError: (error) => {
          toast({
            title: 'Error updating item location',
            description: error?.toString(),
            status: 'error',
            isClosable: true,
          })
        },
      },
    )
    useEffect(() => {
      if (item.data) {
        dispatch(addItem(toItemSubset(item.data)))
      }
    }, [item.data])
    return (
      <>
        <Header title="Item location vinden" />
        <Container mt="4" mb="4">
          <Stack spacing={4}>
            <Box m="-4" mb="0">
              {warehouseId ? (
                <ItemScanner
                  enableHistory
                  onError={setError}
                  onInputChange={setSearch}
                  onScan={onScan}
                  uuid={false}
                  id={search}
                  item={item.data}
                />
              ) : null}
            </Box>
            {error ? (
              <Alert status="error">
                <AlertIcon />
                {typeof error === 'string' ? error : error?.message}
              </Alert>
            ) : null}
            <Suspense fallback={<Loader />}>
              {warehouseId ? (
                <>
                  {!itemId ? null : item.isInitialLoading ? (
                    <Loader />
                  ) : item.error || !item.data ? (
                    <Alert status="error">Could not find item</Alert>
                  ) : null}
                  {item.data && itemId ? (
                    <>
                      <ItemCard
                        item={item.data}
                        imageSize="80px"
                        onShowMore={() => setShowDetails(true)}
                      />
                      {item.data?.state === StorageItemState.WITH_CUSTOMER && (
                        <Alert status="warning">
                          <AlertIcon />
                          Item is with customer, setting location will result in the state changing
                          to stored.
                        </Alert>
                      )}
                      {item.data?.destination && (
                        <Box>
                          <Text color="gray.500" mt="0" mb="1">
                            Suggested location
                          </Text>
                          <WarehouseLocationButtons
                            locations={[item.data.destination]}
                            onSelectLocation={(location: WarehouseLocation) => {
                              setLocation(location)
                            }}
                          />
                        </Box>
                      )}
                      <HStack>
                        <Box flex="2">
                          <WarehouseLocationSelect
                            name={'location'}
                            value={location || ''}
                            warehouseId={warehouseId}
                            onChange={(location: WarehouseLocation | null) => {
                              setLocation(location)
                              location && dispatch(addLocation(location))
                            }}
                          />
                        </Box>
                        <Button
                          flex="1"
                          maxW="60px"
                          isLoading={mutation.isLoading}
                          isDisabled={(!itemId && !location) || mutation.isLoading}
                          variant="outline"
                          onClick={() => {
                            if (search && location) {
                              mutation.mutate({ itemId, location: location.id })
                            }
                          }}
                        >
                          Save
                        </Button>
                      </HStack>

                      <Box>
                        <Text color="gray.500" mt="0" mb="1">
                          Previously used locations
                        </Text>
                        <WarehouseLocationButtons
                          onSelectLocation={(location: WarehouseLocation) => {
                            setLocation(location)
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <Alert status="info">Scan an item</Alert>
                  )}
                </>
              ) : (
                <Alert status="info">Select a warehouse</Alert>
              )}
            </Suspense>
          </Stack>
        </Container>

        <Modal
          isOpen={!warehouseId}
          onClose={() => {
            toast({ title: 'Please select a warehouse' })
          }}
        >
          <ModalOverlay />
          <ModalContent maxW="95vw">
            <ModalHeader>Select warehouse</ModalHeader>
            <ModalBody>
              <WarehouseSelector
                onChange={(id) => {
                  dispatch(setWarehouse(id))
                }}
                value={warehouseId}
                city={filters.city === 'all' ? undefined : filters.city}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          aria-labelledby="item-details"
          isOpen={showDetails}
          onClose={() => setShowDetails(false)}
          scrollBehavior="inside"
          size="full"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              {item.data ? `#${item.data.simpleId} - ${item.data.name}` : null}
            </ModalHeader>
            <ModalBody p="0">
              <Suspense fallback={<Loader size="xl" />}>
                {itemId && <ItemDetailTabs id={itemId} />}
              </Suspense>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  },
)

export default ItemLocationVinden
