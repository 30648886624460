import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import { fetchCurrentUser } from '../api-client/routes/users'

export function useCurrentUser() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  return useQuery(
    ['current-user'],
    async () => {
      const token = await getAccessTokenSilently()
      return fetchCurrentUser({ token })
    },
    { suspense: true, refetchOnMount: false, enabled: isAuthenticated },
  )
}
