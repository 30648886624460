import type { OrderDto } from '@wanda-space/ops-types'
import type { AccountId, OrderContext, UpdateDateTimeBodyDto, WandaId } from '@wanda-space/types'

import type { OrderType, ServiceLevelType, StorageItemType } from '../../consts/enums'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { opsBaseUrl } from '../config'
import type { HistoryObject, OrderLineInput } from '../types'
import type {
  AdminChangeAddressRequest,
  GroupBy2 as GroupOrderBy,
  OrderAdminResponseDto,
  OrderDetailsDto,
  OrderUpdateBodyDto as OrderUpdateModel,
} from '../types-old'

export type NewItem = {
  id: string
  name: string
  type: StorageItemType
  productId?: string
  orderContext?: OrderContext
}
export interface CreateOrderRequest {
  type: OrderType
  storageItemIds: string[]
  orderDetails: OrderDetailsDto
  orderLines: OrderLineInput[]
}
export interface CreateOrderResponseDto {
  orderId: string
  status: string
}

export function createOrder(
  body: CreateOrderRequest,
  {
    accountId,
    allowFailedPayments = false,
    allowClosedTimeslot = false,
  }: { accountId: AccountId; allowFailedPayments?: boolean; allowClosedTimeslot?: boolean },
  token: string,
): Promise<CreateOrderResponseDto> {
  const params = new URLSearchParams({
    accountId,
    allowFailedPayments: allowFailedPayments.toString(),
    allowClosedTimeslot: allowClosedTimeslot.toString(),
  })
  return request({
    method: 'POST',
    url: '/admin/order',
    token,
    body,
    params,
  })
}

export interface CreateOrderWithItemsRequest {
  accountId: AccountId
  itemsToBeCreated: NewItem[]
  order: CreateOrderRequest
  listingId?: string
  allowFailedPayments?: boolean
  allowClosedTimeslot?: boolean
}

export function createOrderWithItems(
  body: CreateOrderWithItemsRequest,
  token: string,
): Promise<CreateOrderResponseDto> {
  return request({
    method: 'POST',
    url: '/admin/order/with-items',
    token,
    body,
  })
}

/** @deprecated use specific endpoints */
export const updateOrder = async ({
  orderId,
  order,
  token,
}: {
  orderId: string
  order: OrderUpdateModel
  token: string
}) => {
  return await request({
    method: 'PUT',
    url: `/admin/order/${orderId}`,
    token,
    body: {
      state: order.state,
      storageItemIds: order.storageItemIds,
      orderDetails: order.orderDetails,
      orderLines: order.orderLines,
    },
  })
}

export const fulfillOrder = async ({
  orderId,
  token,
}: {
  orderId: string
  token: string
}): Promise<OrderAdminResponseDto> => {
  return request({
    method: 'POST',
    url: `/admin/order/${orderId}/fulfill`,
    token,
    body: {},
  })
}

export const rejectOrder = async ({
  orderId,
  token,
}: {
  orderId: string
  token: string
}): Promise<OrderAdminResponseDto> => {
  return request({
    method: 'POST',
    url: `/admin/order/${orderId}/reject`,
    token,
    body: {},
  })
}

export function fetchOrder(orderId: string, token: string): Promise<OrderAdminResponseDto> {
  return request({
    method: 'GET',
    url: `/admin/order/${orderId}`,
    token,
  })
}

export function fetchOrderHistory(
  orderId: string,
  token: string,
): Promise<{ count: number; items: HistoryObject<OrderAdminResponseDto>[] }> {
  return request({
    method: 'GET',
    url: `/admin/order/${orderId}/history`,
    token,
  })
}

export function fetchOrders(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ items: OrderAdminResponseDto[]; count: number }> {
  return fetchChunk({ url: '/admin/order', ...params, search: params.search?.trim() })
}

export function fetchUnclosedOrders(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ items: OrderAdminResponseDto[]; count: number }> {
  return fetchChunk({ url: '/admin/order/unclosed', ...params, search: params.search?.trim() })
}

export function fetchCountOrders({
  from,
  to,
  groupBy,
  signal,
  token,
}: {
  from?: number
  to?: number
  groupBy?: GroupOrderBy[]
  signal?: AbortSignal
  token: string
}): Promise<any> {
  const params = new URLSearchParams()
  if (from) {
    params.set('from', from.toString())
  }
  if (to) {
    params.set('to', to.toString())
  }
  if (groupBy) {
    groupBy.forEach((groupBy) => {
      params.append('groupBy', groupBy)
    })
  }
  return request({
    method: 'GET',
    url: '/admin/order/count',
    params,
    signal,
    token,
  })
}

export async function updateOrderComment({
  id,
  data,
  token,
}: {
  id: string
  data: { extraDeliveryInfo: string }
  token: string
}) {
  return await request({
    method: 'PUT',
    url: `/admin/order/${id}/extra-delivery-info`,
    token,
    body: data,
  })
}

export async function updateOrderAddress({
  id,
  data,
  token,
}: {
  id: string
  data: AdminChangeAddressRequest
  token: string
}) {
  return await request({
    method: 'PUT',
    url: `/admin/order/${id}/update-order-address`,
    token,
    body: data,
  })
}

export const updateOrderItemsAndServiceLevel = async ({
  orderId,
  data,
  token,
}: {
  orderId: string
  data: {
    orderLines: OrderLineInput[]
    serviceLevelType: ServiceLevelType
    floorNumber?: number
    itemsToBeCreated?: NewItem[]
  }
  token: string
}) => {
  return await request({
    method: 'PUT',
    url: `/admin/order/${orderId}/items`,
    token,
    body: {
      orderLines: data.orderLines,
      serviceLevelType: data.serviceLevelType,
      floorNumber: data.floorNumber,
      itemsToBeCreated: data.itemsToBeCreated,
    },
  })
}

export const updateOrderDeliveryDateTime = async ({
  orderId,
  body,
  token,
}: {
  orderId: string
  body: UpdateDateTimeBodyDto
  token: string
}) => {
  return await request({
    method: 'PUT',
    url: `/admin/order/${orderId}/date-time`,
    token,
    body,
  })
}

export async function addCoupon({
  id,
  data,
  token,
}: {
  id: string
  data: { coupon: string }
  token: string
}) {
  return await request({
    method: 'PUT',
    url: `/admin/order/${id}/add-coupon`,
    token,
    body: data,
  })
}

export async function linkServiceOrder({
  orderId,
  serviceOrderId,
  token,
}: {
  orderId: string
  serviceOrderId: string
  token: string
}) {
  return await request({
    method: 'PUT',
    url: `/admin/order/${orderId}/service-order/${serviceOrderId}`,
    token,
    body: {},
  })
}

export function fetchOpsOrder(orderId: string, token: string) {
  return request<OrderDto>({
    url: `/v1/orders/${orderId}/`,
    method: 'GET',
    baseUrl: opsBaseUrl,
    token,
  })
}

export function fetchOpsOrders(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ items: OrderDto[]; count: number }> {
  return fetchChunk({
    url: '/v1/orders/',
    baseUrl: opsBaseUrl,
    ...params,
    search: params.search?.trim(),
  })
}

export async function updateOrderInternalComment({
  id,
  data,
  token,
}: {
  id: string
  data: { internalComment: string }
  token: string
}) {
  return await request({
    method: 'PUT',
    url: `/admin/order/${id}/comments`,
    token,
    body: data,
  })
}
