export class ApiError extends Error {
  public fingerprint: string[]
  constructor(
    public readonly url: string,
    public readonly options: { method: string },
    public readonly response: Response,
    public readonly body?: Record<string, unknown>,
  ) {
    super(`Request to ${url} failed with ${response.statusText} (${response.status})`)
    Object.setPrototypeOf(this, ApiError.prototype)
    this.name = this.constructor.name
    this.fingerprint = [
      this.url,
      this.options.method,
      this.response.status.toString(),
      typeof this.body?.wandaCode === 'string' ? this.body.wandaCode : JSON.stringify(this.body),
    ]
  }

  status(): number {
    return this.response.status
  }

  shouldReportToSentry(): boolean {
    const isImage502 = this.response.url.includes('image') && this.response.status === 502
    return this.response.status >= 500 && !isImage502
  }
}
