import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import type { App } from '@wanda-space/ops-types'

import { getHelpTexts } from '../api-client/routes/help-text'

export const useRecentUpdates = (app: App, options?: { suspense: boolean }) => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery(
    ['texts'],
    async () => {
      const token = await getAccessTokenSilently()
      return getHelpTexts({
        token,
        orderBy: 'createdAt',
        orderDir: 'DESC',
        itemsPerPage: 5,
        search: undefined,
        page: 1,
        filters: { app, type: 'FEATURE' },
      })
    },
    options,
  )
}
