import { useAuth0 } from '@auth0/auth0-react'
import { captureException } from '@sentry/react'

import { postRouteEvent } from 'api/routes/routes'
import type { RouteEvent } from 'interface'

export function useRouteEventTracker() {
  const { getAccessTokenSilently } = useAuth0()

  return async (event: RouteEvent) => {
    const token = await getAccessTokenSilently()
    try {
      return await postRouteEvent({
        token,
        event,
      })
    } catch (err) {
      captureException(err)
    }
  }
}
