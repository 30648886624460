import type { AccountId, PaginatedResponseDto, WandaId } from '@wanda-space/types'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { userBaseUrl as baseUrl } from '../config'
import type { AccountDto, UserV2Dto } from '../types'

export function fetchCurrentAccount(token: string): Promise<AccountDto> {
  return request({
    baseUrl,
    method: 'GET',
    url: '/v2/accounts/active',
    token,
  })
}

export async function switchAccount({ accountId, token }: { accountId: AccountId; token: string }) {
  return request<AccountDto>({
    method: 'PUT',
    url: '/v2/accounts/active',
    baseUrl,
    body: { accountId },
    token,
  })
}

export function fetchAccounts(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ items: AccountDto[]; count: number }> {
  const { page, search, ...rest } = params
  return fetchChunk({
    baseUrl,
    url: '/v2/admin/accounts',
    search: search?.trim(),
    page,
    ...rest,
  })
}

export function fetchAccountsForUser(params: {
  userId: WandaId
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ items: AccountDto[]; count: number }> {
  const { page, search, ...rest } = params
  return fetchChunk({
    baseUrl,
    url: `/v2/admin/accounts/by-user/${params.userId}`,
    search: search?.trim(),
    page,
    ...rest,
  })
}

export function fetchAccount({ id, token }: { id: string; token: string }): Promise<AccountDto> {
  return request({
    baseUrl,
    method: 'GET',
    url: `/v2/admin/accounts/${id}`,
    token,
  })
}

export function fetchAccountMembers({
  id,
  params,
  token,
}: {
  id: string
  token: string
  params: {
    orderBy: string
    orderDir: 'ASC' | 'DESC'
    itemsPerPage: number
    page: number
    search: string | undefined
    filters: ApiFilters | undefined
  }
}): Promise<PaginatedResponseDto<UserV2Dto>> {
  return fetchChunk({
    baseUrl,
    url: `/v2/admin/accounts/${id}/users`,
    token,
    ...params,
  })
}
