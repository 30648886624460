export enum EnvKeys {
  APP_ENVIRONMENT = 0,
  AUTH0_DOMAIN = 1,
  AUTH0_CLIENT_ID = 2,
  AUTH0_NAMESPACE = 3,
  AUTH0_AUDIENCE = 4,
}
export type EnvKey = keyof typeof EnvKeys

// @ts-ignore _env_ is injected by the server
const _env_ = window['_env_']
const env = (
  typeof _env_ === 'string' ? JSON.parse(decodeURIComponent(_env_ || '%7B%7D')) : _env_ || {}
) as Record<EnvKey, string | undefined>

const defaults: Partial<Record<EnvKey, string>> = {
  APP_ENVIRONMENT: 'local',
  AUTH0_DOMAIN: 'id-dev.wanda.space',
  AUTH0_AUDIENCE: 'https://admin.dev.wanda.space/api',
  AUTH0_NAMESPACE: '',
}

export function getEnvKeys(): EnvKey[] {
  return Object.keys(EnvKeys).filter((key) => Number.isNaN(Number.parseInt(key, 10))) as EnvKey[]
}

export function getEnv(key: EnvKey): string {
  let value = env[key] || process.env[key] || defaults[key]
  if (key === 'AUTH0_CLIENT_ID') {
    // @ts-ignore tmp fix
    value = env[key] || env.AUTH0_CLIENTID || process.env[key] || defaults[key]
  }

  if (!value) {
    throw new Error(`Missing env variable: ${key}`)
  }

  return value
}
