import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

import ImageUploader from '../../assets/image-uploader.svg'

type Props = {
  src?: string
  error?: boolean
}

const ItemImage = ({ src, error }: Props) => {
  return (
    <Flex height="100%" width="100%" justifyContent="center">
      <Box maxWidth="400px" height="100%" width="100%">
        <Flex
          position="relative"
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          border="1px solid"
          borderColor={error ? 'pink.800' : 'transparent'}
        >
          <Flex
            position="absolute"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            top="0"
            left="0"
          >
            {src ? (
              <Box display="block" height="100%">
                <img src={src} alt="" height="100%" width="auto" />
              </Box>
            ) : (
              <ImageUploader viewBox="0 0 183 183" />
            )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default ItemImage
