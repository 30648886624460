import type { OrderDto } from '@wanda-space/ops-types'
import type { RouteModel, RouteStopModel } from './RouteModel'

export interface RouteEvent {
  name: string
  target?: string
  targetId?: string
  metadata?: object
}

enum EventName {
  // Sent when click on route on the route list page
  ROUTE_CLICK = 'route_click',

  // Sent when route details page is loaded
  ROUTE_LOAD = 'route_load',

  // Sent when click on directions link
  DIRECTIONS_CLICK = 'directions_click',

  // Sent when sms is sent
  SMS_SEND = 'sms_send',

  // Sent when click on call link
  CALL_CLICK = 'call_click',

  // Sent when clicking on an order in a route stop
  ORDER_CLICK = 'order_click',
}

export enum RouteStopOrderAction {
  LOAD = 'LOAD',
  DROP_OFF = 'DROP_OFF',
}

export const routeClickEvent = (route: RouteModel) => ({
  name: EventName.ROUTE_CLICK,
  target: 'ROUTE',
  targetId: route.id,
  metadata: undefined,
})

export const routeLoadEvent = (route: RouteModel) => ({
  name: EventName.ROUTE_LOAD,
  target: 'ROUTE',
  targetId: route.id,
  metadata: undefined,
})

export const directionsClickEvent = (routeStop: RouteStopModel) => ({
  name: EventName.DIRECTIONS_CLICK,
  target: 'ROUTE_STOP',
  targetId: routeStop.id,
  metadata: undefined,
})

export const smsSendEvent = (routeStop: RouteStopModel) => ({
  name: EventName.SMS_SEND,
  target: 'ROUTE_STOP',
  targetId: routeStop.id,
  metadata: undefined,
})

export const callClickEvent = (routeStop: RouteStopModel) => ({
  name: EventName.CALL_CLICK,
  target: 'ROUTE_STOP',
  targetId: routeStop.id,
  metadata: undefined,
})

export const orderClickEvent = (
  routeStop: RouteStopModel,
  order: OrderDto,
  orderAction: RouteStopOrderAction,
) => ({
  name: EventName.ORDER_CLICK,
  target: 'ROUTE_STOP',
  targetId: routeStop.id,
  metadata: {
    order: order.id,
    type: orderAction,
  },
})
