import { Alert, AlertDescription, AlertIcon, AlertTitle, Text } from '@chakra-ui/react'
import React from 'react'
import { TechSupportLink } from '../components'
import { type UserRole, useRequiredRoles, useRoles } from '../hooks'

/**
 * This is a HOC that can be used to wrap a component and require certain role to be present.
 * It will require theu user to have **one of** the roles listed in the array, not all of them.
 * See also useRequiredRoles.
 */
export function requireRoles<P extends {}>(
  options: UserRole[] | { requireRoles: UserRole[]; hideIfMissing?: boolean },
  Component: React.ComponentType<P>,
) {
  return (props: P) => {
    const roles = Array.isArray(options) ? options : options.requireRoles
    const hideIfMissing = Array.isArray(options) ? false : options.hideIfMissing
    const userRoles = useRoles()
    const isInternal = userRoles.some((role) => !role.toLowerCase().includes('external'))
    const hasAccess = useRequiredRoles(roles)
    if (hasAccess) {
      return <Component {...props} />
    }
    if (hideIfMissing) {
      return null
    }
    return (
      <Alert
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        minH="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Missing permissions
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          It looks like you are missing the role to access this feature.{' '}
          {isInternal ? (
            <>
              If this is wrong, please contact <TechSupportLink /> and we will help you out.
              <br />
              <Text as="span" fontWeight="semibold">
                Roles with access:
              </Text>{' '}
              {roles.join(', ')}
            </>
          ) : (
            <>Contact your manager to request access.</>
          )}
        </AlertDescription>
      </Alert>
    )
  }
}
