import type {
  AccountId,
  ActualServiceLevel,
  CurrentPossessor,
  Extraorderlines,
  ItemDto,
  TargetPossessor,
} from '@wanda-space/ops-types'

import type { Product } from '../api-client/routes/products'
import type { SupportedCities, SupportedCountries } from '../consts/enums'

export type HandoverItem = Pick<ItemDto, 'id' | 'type' | 'name'> & {
  ownerId: AccountId
  simpleId: string
}

export type HandoverConcludeItem = HandoverItem & {
  scanned?: boolean
  comment: string | undefined
}

export enum HandoverSourceType {
  ORDER = 'ORDER',
  SERVICE_ORDER = 'SERVICE_ORDER',
  HANDOVER = 'HANDOVER',
  MANUAL_BY_USER = 'MANUAL_BY_USER',
  ROUTINE = 'ROUTINE',
}
export enum HandoverPossessorType {
  CUSTOMER = 'CUSTOMER',
  WANDA_EMPLOYEE = 'WANDA_EMPLOYEE',
  WAREHOUSE = 'WAREHOUSE',
  WAREHOUSE_OUTBOUND = 'WAREHOUSE_OUTBOUND',
  PARTNER = 'PARTNER',
  LOCATION = 'LOCATION',
  ROUTE_OUTBOUND = 'ROUTE_OUTBOUND',
}
export enum HandoverState {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  CONCLUDED = 'CONCLUDED',
  CANCELLED = 'CANCELLED',
}
export type HandoverPossessor = {
  type: HandoverPossessorType
  id: string
}
export type Source = { type: HandoverSourceType; id: string }

export type HandoverInProgress = {
  id: string
  createdAt: string
  updatedAt: string
  currentPossessor: HandoverPossessor
  targetPossessor: HandoverPossessor
  expectedItems: HandoverItem[]
  relatedOrderIds: string[]
  countryCode: SupportedCountries
  city: SupportedCities
  source: Source
}
export interface HandoverConclusionDto {
  itemsHandedOver: HandoverConcludeItem[]
  concludedAt: string
  concludedBy: string
  hasDiscrepancies: boolean
  itemsAdded: HandoverItem[]
  itemsMissing: HandoverItem[]
  [key: string]: unknown
}

export interface HandoverDto {
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string | null
  source: Source
  relatedOrderIds: string[]
  state: HandoverState
  expectedItems: HandoverItem[]
  currentPossessor: HandoverPossessor
  targetPossessor: HandoverPossessor
  countryCode: SupportedCountries
  city: SupportedCities
  conclusion: {
    itemsAdded: HandoverItem[]
    itemsMissing: HandoverItem[]
    itemsHandedOver: HandoverConcludeItem[]
    hasDiscrepancies: boolean
    concludedAt: string
    concludedBy: string
    comment?: string
    extraOrderlines?: Extraorderlines
    actualServiceLevel?: ActualServiceLevel
  } | null
}

export interface CreateHandoverDto {
  storageItems: HandoverItem[]
  source: Source
  currentPossessor: CurrentPossessor
  targetPossessor: TargetPossessor
  initialState: HandoverState.OPEN
  city: SupportedCities
}
export interface ConcludeHandoverDto {
  itemsHandedOver: HandoverConcludeItem[]
  comment?: string
  extraOrderlines?: Extraorderlines
  actualServiceLevel?: ActualServiceLevel
  hasFulfilledTimeslot?: boolean
}

export interface HandoverExtraOrderlines {
  item?: ItemDto
  product: Product
  quantity: number
}
