import type { SupportedCities, SupportedCountries } from '../consts/enums'
import { request } from './api-client'

export type ApiFilters = {
  city?: SupportedCities
  countryCode?: SupportedCountries
  [key: string]: any
}

function getChunksOfArray<T>(array: T[], chunkSize: number) {
  const range = (size: number) => Array.from({ length: size }, (_, i) => i).map((x, i) => i)

  return range(Math.ceil(array.length / chunkSize)).map((_, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize),
  )
}

export async function fetchChunk<T>({
  baseUrl,
  url,
  page,
  itemsPerPage,
  orderBy,
  orderDir,
  search,
  filters,
  params,
  withDeleted = false,
  token,
}: {
  baseUrl?: string
  url: string
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  params?: URLSearchParams
  withDeleted?: boolean
  token: string
}): Promise<{ items: T[]; count: number }> {
  if (filters?.id && Array.isArray(filters.id)) {
    const chunkSize = 40
    if (filters.id.length > chunkSize) {
      if (page > 1) {
        throw new Error('Cannot paginate id filtering requests')
      }
      const idChunk = getChunksOfArray(filters.id, chunkSize)
      const responses = await Promise.all(
        idChunk.map((ids) =>
          fetchChunk<T>({
            baseUrl,
            url,
            page,
            itemsPerPage: ids.length,
            orderBy,
            orderDir,
            search,
            filters: { ...filters, id: ids },
            params,
            token,
          }),
        ),
      )
      const items = responses.flatMap((r) => r.items)
      return {
        items,
        count: items.length,
      }
    }
  }

  const searchParams = new URLSearchParams({
    orderBy,
    orderDir,
    itemsPerPage: itemsPerPage.toString(),
    page: page.toString(),
  })
  if (withDeleted) {
    searchParams.set('withDeleted', withDeleted.toString())
  }
  if (filters) {
    searchParams.set('filters', JSON.stringify(filters))
  }
  if (search) {
    searchParams.set('search', search.trim())
  }
  if (params) {
    for (const [key, value] of params.entries()) {
      searchParams.set(key, value)
    }
  }
  return request({
    method: 'GET',
    baseUrl,
    url,
    token,
    params: searchParams,
  })
}
