import { Badge, Box } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

import type { OrderAdminResponseDto } from '../api-client/types-old'
import { useOrders } from '../hooks/useOrders'

export function OrderLink({
  orderId,
  showState = false,
  showSimpleId = true,
  title,
  newTab,
}: {
  orderId: string
  showState?: boolean
  showSimpleId?: boolean
  title?: string
  newTab?: boolean
}) {
  const { data, isSuccess } = useOrders(
    { filters: { id: orderId } },
    { enabled: showSimpleId, refetchOnMount: false },
  )
  const order: OrderAdminResponseDto | undefined = data?.items[0]
  let text = ''
  if (isSuccess && showSimpleId && order) {
    text = `#${order.simpleId}`
  } else {
    text = orderId
  }
  const link = (
    <Link
      target={newTab ? '_blank' : '_self'}
      style={{ textDecoration: 'underline' }}
      to={`/orders/${orderId}`}
      title={title}
    >
      {text}
    </Link>
  )

  if (showState) {
    return (
      <Box as="span" display="inline-flex" alignItems="baseline">
        {link}
        <Badge ml="2" size="sm">
          {order?.state}
        </Badge>
      </Box>
    )
  }
  return link
}
