import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import { fetchOpsItems, fetchOpsItemsByOrder } from '../api-client/routes/items'
import type { OrderLine } from '../api-client/types'

export function useOpsItems(
  params: {
    search?: string
    filters: Record<string, string[] | string | undefined>
    itemsPerPage?: number
    page?: number
  },
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['ops-items', params],
    async () => {
      const token = await getAccessTokenSilently()
      return (await fetchOpsItems({ search: undefined, ...params, token })).items
    },
    options,
  )
}
export function useOpsItemsForOrder(
  orderId: string,
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['ops-items', orderId],
    async () => {
      const token = await getAccessTokenSilently()
      return fetchOpsItemsByOrder({ orderId, token })
    },
    options,
  )
}

export function useOpsItemsFromOrderLines(
  order: { orderLines: OrderLine[] } | undefined,
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  return useOpsItems(
    {
      filters: {
        id: order?.orderLines.map((ol) => ol.item?.id).filter((id) => id !== null) as string[],
      },
    },
    options,
  )
}
