import { useAuth0 } from '@auth0/auth0-react'
import { type UseQueryOptions, useQuery } from '@tanstack/react-query'
import type { AccountDto } from '../api-client/types'

import { fetchAccount } from '../api-client/routes/accounts'

export const useAccount = <TError>(
  id: string | undefined,
  options?: Omit<
    UseQueryOptions<Promise<AccountDto>, TError, AccountDto, string[]>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery<Promise<AccountDto>, TError, AccountDto, string[]>(
    ['account', id!],
    async () => fetchAccount({ id: id!, token: await getAccessTokenSilently() }),
    { ...options, enabled: options?.enabled ?? !!id },
  )
}
