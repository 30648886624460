import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'
import type { TextDto } from '@wanda-space/ops-types'
import React from 'react'

export function UpdatesCard({ text }: { text: TextDto }) {
  return (
    <Card>
      <CardHeader>
        <Heading size="sm">{text.title}</Heading>
      </CardHeader>
      <CardBody maxHeight="250px" overflowY="scroll">
        {text.content}
      </CardBody>
    </Card>
  )
}
