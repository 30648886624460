import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import type { AccountId } from '@wanda-space/types'
import React from 'react'
import { switchAccount } from '../api-client/routes/accounts'
import { useCurrentUser } from '../hooks'
import { useCurrentAccount } from '../hooks/useCurrentAccount'
import { TechSupportLink } from './TechSupportLink'

export function NotDefaultAccountModal() {
  const toast = useToast()
  const { data: user } = useCurrentUser()
  const { data: account } = useCurrentAccount()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const mutation = useMutation(
    async (accountId: AccountId) =>
      switchAccount({
        accountId,
        token: await getAccessTokenSilently(),
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Switched account',
          status: 'success',
          isClosable: true,
        })
        window.location.reload()
      },
      onError: (error) => {
        toast({
          title: 'Error switching account',
          description: error?.toString(),
          status: 'error',
          isClosable: true,
        })
      },
    },
  )

  if (!isAuthenticated || user?.defaultAccountId === account?.id) {
    return null
  }

  return (
    <Modal
      isOpen={true}
      onClose={() =>
        toast({
          description: 'You must switch account to continue',
          status: 'info',
          isClosable: true,
        })
      }
      size="sm"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="2xl" mb="0">
            Ooops, it looks like you are using a shared account
          </Text>
        </ModalHeader>
        <ModalBody>
          <VStack>
            <Text>
              Our internal tools does not work with shared accounts, please switch to your default
              account by clicking the button below It looks like there is no city assigned to your
              account.
            </Text>
            <Text>
              In the case you are having issues switching to the correct account, please post in{' '}
              <TechSupportLink /> on slack.
            </Text>
            <Button
              m="4"
              w="80%"
              isLoading={mutation.isLoading}
              disabled={!!user?.defaultAccountId}
              onClick={() => {
                console.log(user?.defaultAccountId)
                if (user?.defaultAccountId) {
                  mutation.mutate(user?.defaultAccountId)
                }
              }}
            >
              Switch account
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
