import { alertAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  alertAnatomy.keys,
)

const baseStyle = definePartsStyle({
  container: {
    borderRadius: '8px',
  },
})

export const alertTheme = defineMultiStyleConfig({ baseStyle })
