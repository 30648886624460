import { Box, type SpaceProps, type ThemeTypings } from '@chakra-ui/react'
import { WandaSpinner } from '@wanda-space/noelle'
import React, { useEffect, useState } from 'react'

interface LoaderProps extends SpaceProps {
  color?: ThemeTypings['colors']
  size?: ThemeTypings['sizes']
  fullHeight?: boolean
  wait?: number
}

function getSize(size?: ThemeTypings['sizes']) {
  switch (size) {
    case 'xl':
      return '80px'
    case 'lg':
      return '32px'
    case 'md':
      return '24px'
    case 'sm':
      return '16px'
    default:
      return size
  }
}

const Loader = ({ color, fullHeight, size, wait = 0, ...rest }: LoaderProps) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const id = setTimeout(() => {
      setShow(true)
    }, wait)
    return () => clearTimeout(id)
  })

  return (
    <Box
      alignItems="center"
      display="flex"
      opacity={show ? 1 : 0}
      transition="opacity 0.15s ease-in-out"
      flex="1"
      height={fullHeight ? '100%' : undefined}
      justifyContent="center"
      p={size === 'xl' ? '10' : undefined}
      {...rest}
    >
      <WandaSpinner
        height={getSize(fullHeight ? 'xl' : size)}
        width={getSize(fullHeight ? 'xl' : size)}
        color={color || 'purple.500'}
      />
    </Box>
  )
}

export default Loader
