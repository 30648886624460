import { Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'

import type { WarehouseLocation } from 'api/routes/warehouse'
import { useAppSelector } from 'hooks/useAppSelector'
import { addLocation, selectRecentLocations } from 'store/itemLookupHistory'

type Props = {
  onSelectLocation: (location: WarehouseLocation) => void
  locations?: WarehouseLocation[]
}

export function WarehouseLocationButtons({ onSelectLocation, locations }: Props) {
  const recentLocations = useAppSelector(selectRecentLocations)
  const dispatch = useDispatch()
  return (
    <Flex direction="row" justifyContent="space-evenly" rowGap={2} flexWrap="wrap">
      {(locations ?? recentLocations).map((location) => (
        <Button
          key={location.id}
          onClick={() => {
            dispatch(addLocation(location))
            onSelectLocation(location)
          }}
          w="40%"
          colorScheme="gray"
          variant="outline"
          size="md"
          justifyContent="space-around"
        >
          <Text fontSize="sm" overflow="hidden" whiteSpace="nowrap">
            {location.label}
          </Text>
        </Button>
      ))}
    </Flex>
  )
}
