import React from 'react'

import { useWarehouseLocation } from '../../hooks/useWarehouseLocation'
import { Warehouse } from '../Warehouse/Warehouse'

export function WarehouseLocation({
  id,
  showWarehouse = false,
}: {
  id: string
  showWarehouse?: boolean
}) {
  const { data } = useWarehouseLocation({ id }, { suspense: true })
  return (
    <span data-testid="WarehouseLocation">
      {data ? (
        <>
          {showWarehouse && (
            <>
              <Warehouse id={data.warehouse} />{' '}
            </>
          )}
          {data.label}
        </>
      ) : (
        id
      )}
    </span>
  )
}
