import { useAuth0 } from '@auth0/auth0-react'
import { ON_LOGOUT_CLEAR_LOCAL_STORAGE_KEYS } from 'consts'

export function useLogout() {
  const { logout: auth0Logout } = useAuth0()
  return {
    logout: () => {
      auth0Logout()
      sessionStorage.clear()
      ON_LOGOUT_CLEAR_LOCAL_STORAGE_KEYS.forEach((key) => localStorage.removeItem(key))
    },
  }
}
