import { Icon, IconButton, useToast } from '@chakra-ui/react'
import React from 'react'
import { MdCopyAll } from 'react-icons/md'

export function CopyToClipboard({
  value,
  size = 'sm',
}: { value: string | undefined; size?: 'sm' | 'md' }) {
  const toast = useToast()
  if (!value) {
    return null
  }

  return (
    <IconButton
      size={size}
      variant="ghost"
      onClick={() => {
        try {
          navigator.clipboard.writeText(value)
          toast({ title: 'Copied to clipboard 🎉' })
        } catch (error: any) {
          toast({ title: 'Could not copy to clipboard 😭' })
        }
      }}
      icon={<Icon as={MdCopyAll} />}
      aria-label={'Copy to clipboard'}
      title="Copy to clipboard"
    />
  )
}
