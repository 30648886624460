import styled from '@emotion/styled'
import QrScanner from 'qr-scanner'
import React, { memo, useLayoutEffect, useRef } from 'react'

const Wrapper = styled.div`
  overflow: hidden;
  height: 200px;
  z-index: 100;

  canvas {
    max-width: 100%;
    height: 200px;
    margin: 0 auto;
  }

  video {
    max-height: 0;
    height: 0;
  }
`

type Props = {
  onScan: (result: QrScanner.ScanResult) => void | Promise<void>
  onError: (error: Error) => void
}
function QrCodeScanner(props: Props) {
  const video = useRef<HTMLVideoElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    let scanner: QrScanner | null = null
    let timeoutId: any | null = null

    const videoElement = video.current
    if (videoElement) {
      timeoutId = setTimeout(() => {
        scanner = new QrScanner(videoElement, props.onScan, {
          preferredCamera: 'environment',
          returnDetailedScanResult: true,
          maxScansPerSecond: 25,
          highlightScanRegion: true,
          highlightCodeOutline: true,
          calculateScanRegion: (video) => {
            if (video.videoWidth > video.videoHeight) {
              return {
                x: (video.videoWidth - video.videoHeight) / 2,
                y: 0,
                height: video.videoHeight / 2,
                width: video.videoHeight,
              }
            }
            return {
              x: 0,
              y: (video.videoHeight - video.videoWidth) / 2,
              height: video.videoWidth / 2,
              width: video.videoWidth,
            }
          },
        })

        if (wrapper.current) {
          wrapper.current.appendChild(scanner.$canvas)
        }

        console.log('created scanner', scanner)

        scanner.start().catch((error) => props.onError(error))
      }, 300)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      if (scanner) {
        scanner.stop()
        scanner.destroy()
        if (wrapper.current) {
          wrapper.current.removeChild(scanner.$canvas)
        }
        console.log('destroyed scanner')
        scanner = null
      }
    }
  }, [video.current])

  return (
    <Wrapper ref={wrapper}>
      <video ref={video} muted autoPlay style={{ display: 'block' }} />
    </Wrapper>
  )
}

export const Scanner = memo(QrCodeScanner)
