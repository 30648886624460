import type { TextDto } from '@wanda-space/ops-types'
import type { Type } from '@wanda-space/ops-types/dist/TextDto'

import { type ApiFilters, fetchChunk } from '../chunk'
import { opsBaseUrl } from '../config'

export type TextType = Type

export async function getHelpTexts(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ count: number; items: TextDto[] }> {
  return fetchChunk({
    ...params,
    baseUrl: opsBaseUrl,
    url: '/v1/texts/',
    page: params.page === 0 ? 1 : params.page,
  })
}
