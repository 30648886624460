import { createSlice } from '@reduxjs/toolkit'

import type { SupportedCities } from 'consts/enum'
import { format } from 'date-fns'

interface FiltersState {
  date: string | undefined
  city: SupportedCities | 'all'
  warehouse: string | 'all'
}

export const initialState = {
  date: format(new Date(), 'yyyy-MM-dd'),
  city: 'all',
  warehouse: 'all',
} as FiltersState

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.date = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
    },
    setWarehouse: (state, action) => {
      state.warehouse = action.payload
    },
  },
})

export const { setDate, setCity, setWarehouse } = slice.actions
export default slice.reducer
