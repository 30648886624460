import { v4 as uuid } from 'uuid'

export function getOrCreateSessionId() {
  let id = sessionStorage.getItem('x-wanda-session-id')
  if (!id) {
    id = uuid()
    sessionStorage.setItem('x-wanda-session-id', id)
  }
  return id
}
