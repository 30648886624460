import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { captureException } from '@sentry/react'

import {
  LOCAL_STORAGE_KEY_ITEM_LOOKUP_HISTORY_STATE,
  SESSION_STORAGE_KEY_CONSIGNMENT_STATE,
  SESSION_STORAGE_KEY_FILTERS,
  SESSION_STORAGE_KEY_HANDOVER_STATE,
} from 'consts'
import { format } from 'date-fns'
import consignment from './consignment'
import filters from './filters'
import handover, { initialState as handoverInitialState } from './handover'
import itemLookupHistory, { initialState as itemLookupInitialState } from './itemLookupHistory'
import orders from './orders'
import routes from './routes'

const reducer = combineReducers({
  filters,
  orders,
  routes,
  handover,
  itemLookupHistory,
  consignment,
})

let filtersState: any
try {
  const persistedState = sessionStorage.getItem(SESSION_STORAGE_KEY_FILTERS)
  filtersState = persistedState ? JSON.parse(persistedState) : undefined
} catch (error) {
  captureException(error)
}
let handoverState: any
try {
  const persistedState = sessionStorage.getItem(SESSION_STORAGE_KEY_HANDOVER_STATE)
  handoverState = persistedState ? JSON.parse(persistedState) : undefined
} catch (error) {
  captureException(error)
}
let itemLookupHistoryState: any
try {
  const persistedState = localStorage.getItem(LOCAL_STORAGE_KEY_ITEM_LOOKUP_HISTORY_STATE)
  itemLookupHistoryState = persistedState ? JSON.parse(persistedState) : undefined
} catch (error) {
  captureException(error)
}

let consignmentState: any
try {
  const persistedState = sessionStorage.getItem(SESSION_STORAGE_KEY_CONSIGNMENT_STATE)
  consignmentState = persistedState ? JSON.parse(persistedState) : undefined
} catch (error) {
  captureException(error)
}

const preloadedState = {}
if (filtersState) {
  //@ts-ignore can't get store type before making store
  preloadedState.filters = {
    date: format(new Date(), 'yyyy-MM-dd'),
    city: filtersState.city ?? 'all',
    warehouse: filtersState.warehouse ?? 'all',
  }
}
if (handoverState) {
  //@ts-ignore can't get store type before making store
  preloadedState.handover = { ...handoverInitialState, ...handoverState, error: false }
}
if (itemLookupHistoryState) {
  //@ts-ignore can't get store type before making store
  preloadedState.itemLookupHistory = { ...itemLookupInitialState, ...itemLookupHistoryState }
}

if (consignmentState) {
  //@ts-ignore can't get store type before making store
  preloadedState.consignment = { ...consignmentState }
}

const store = configureStore({
  reducer,
  preloadedState,
})
store.subscribe(() => {
  sessionStorage.setItem(SESSION_STORAGE_KEY_FILTERS, JSON.stringify(store.getState().filters))
  sessionStorage.setItem(
    SESSION_STORAGE_KEY_HANDOVER_STATE,
    JSON.stringify(store.getState().handover),
  )
  localStorage.setItem(
    LOCAL_STORAGE_KEY_ITEM_LOOKUP_HISTORY_STATE,
    JSON.stringify(store.getState().itemLookupHistory),
  )
  sessionStorage.setItem(
    SESSION_STORAGE_KEY_CONSIGNMENT_STATE,
    JSON.stringify(store.getState().consignment),
  )
})
export default store

export type RootState = ReturnType<typeof store.getState>
