import {
  type EntityState,
  type PayloadAction,
  type SerializedError,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import type { ExpectedItems, ItemDto } from '@wanda-space/ops-types'
import type {
  ConcludeHandoverDto,
  CreateHandoverDto,
  HandoverConcludeItem,
  HandoverExtraOrderlines,
  HandoverInProgress,
  HandoverItem,
} from '@wanda/shared'
import {
  concludeHandover as concludeHandoverEndpoint,
  createHandover as createHandoverEndpoint,
  refreshHandover as refreshHandoverEndpoint,
} from '@wanda/shared/src/api-client/routes/handovers'
import type { Product } from '@wanda/shared/src/api-client/routes/products'
import { assoc, difference, dissoc, o, prop } from 'ramda'

interface HandoverState {
  id: string
  handover: HandoverInProgress
  selectedItems: Record<string, HandoverConcludeItem>
  itemComments: Record<string, string>
  itemsAdded: HandoverItem[]
  productCountDraft?: Record<string, number>
  extraOrderlines: HandoverExtraOrderlines[]
}

export const createHandover = createAsyncThunk(
  'handover/createHandover',
  async (data: { body: CreateHandoverDto; token: string }) => {
    const handover = await createHandoverEndpoint(data)
    return handover
  },
)
export const refreshHandover = createAsyncThunk(
  'handover/refreshHandover',
  async (data: { id: string; expectedItems: ExpectedItems; token: string }) => {
    return await refreshHandoverEndpoint(data)
  },
)
export const concludeHandover = createAsyncThunk(
  'handover/concludeHandover',
  async (data: { body: ConcludeHandoverDto; token: string; id: string }) => {
    return await concludeHandoverEndpoint(data)
  },
)
const handoverAdapter = createEntityAdapter<HandoverState>()

export function selectExpectedItems(state: HandoverState | undefined) {
  if (state) {
    return state.handover.expectedItems.concat(state.itemsAdded) || []
  }
  return []
}

export type State = {
  submitting: boolean
  error: SerializedError | undefined
  activeHandovers: EntityState<HandoverState, string>
}
export const initialState: State = {
  submitting: false,
  error: undefined,
  activeHandovers: handoverAdapter.getInitialState(),
}
export const slice = createSlice({
  name: 'handover',
  initialState,
  reducers: {
    updateHandoverState: (
      state,
      { payload }: PayloadAction<Partial<Omit<HandoverState, 'id'>> & Pick<HandoverState, 'id'>>,
    ) => {
      handoverAdapter.updateOne(state.activeHandovers, {
        id: payload.id,
        changes: { ...payload },
      })
    },
    toggleItem: (
      state,
      { payload }: PayloadAction<{ handoverId: string; item: HandoverItem; scanned: boolean }>,
    ) => {
      const current = handoverAdapter
        .getSelectors()
        .selectById(state.activeHandovers, payload.handoverId)
      if (!current) {
        throw new Error('Handover not found')
      }
      const expectedItems = selectExpectedItems(current)

      if (current && expectedItems.map(prop('id')).includes(payload.item.id)) {
        handoverAdapter.updateOne(state.activeHandovers, {
          id: payload.handoverId,
          changes: {
            selectedItems: Object.keys(current.selectedItems).includes(payload.item.id)
              ? dissoc(payload.item.id, current.selectedItems)
              : assoc(
                  payload.item.id,
                  {
                    ...payload.item,
                    scanned: payload.scanned,
                    comment: current.itemComments[payload.item.id],
                  },
                  current.selectedItems,
                ),
          },
        })
      }
    },
    updateItemComment: (
      state,
      { payload }: PayloadAction<{ handoverId: string; itemId: string; comment: string }>,
    ) => {
      const selected =
        state.activeHandovers.entities[payload.handoverId]?.selectedItems[payload.itemId]
      handoverAdapter.updateOne(state.activeHandovers, {
        id: payload.handoverId,
        changes: {
          selectedItems: {
            ...state.activeHandovers.entities[payload.handoverId]?.selectedItems,
            ...(selected ? { [payload.itemId]: { ...selected, comment: payload.comment } } : {}),
          },
          itemComments: {
            [payload.itemId]: payload.comment,
          },
        },
      })
    },
    addItemToHandedOverItems: (
      state,
      { payload }: PayloadAction<{ handoverId: string; item: HandoverItem; scanned: boolean }>,
    ) => {
      const current = handoverAdapter
        .getSelectors()
        .selectById(state.activeHandovers, payload.handoverId)
      if (!current) {
        throw new Error('Handover not found')
      }
      const expectedItems = selectExpectedItems(current)
      if (current && expectedItems.map(prop('id')).includes(payload.item.id)) {
        handoverAdapter.updateOne(state.activeHandovers, {
          id: payload.handoverId,
          changes: {
            selectedItems: assoc(
              payload.item.id,
              {
                ...payload.item,
                scanned: payload.scanned,
                comment: current.itemComments[payload.item.id],
              },
              current.selectedItems,
            ),
          },
        })
      }
    },
    removeHandoverState: (state, { payload }: PayloadAction<string>) => {
      handoverAdapter.removeOne(state.activeHandovers, payload)
    },
    createHandoverState: (state, { payload }: PayloadAction<HandoverInProgress>) => {
      handoverAdapter.addOne(state.activeHandovers, {
        id: payload.id,
        handover: payload,
        selectedItems: {},
        itemsAdded: [],
        itemComments: {},
        extraOrderlines: [],
      })
    },
    toggleExtraOrderlines: (
      state,
      {
        payload,
      }: PayloadAction<{
        handoverId: string
        item?: ItemDto
        scanned: boolean
        product: Product
        quantity?: number
      }>,
    ) => {
      const current = handoverAdapter
        .getSelectors()
        .selectById(state.activeHandovers, payload.handoverId)
      if (!current) {
        throw new Error('Handover not found')
      }

      const baseOrderline = {
        product: payload.product,
        item: payload.item,
        quantity: 1,
      }

      let shouldRemoveOrderline = payload.quantity === -1

      const orderlineInput = [...new Array(shouldRemoveOrderline ? 0 : payload.quantity ?? 1)].map(
        () => baseOrderline,
      )

      const updatedOrderlines = (current.extraOrderlines ?? []).flatMap(
        (orderline, index, extraOrderlines) => {
          const isExistingOrderlineByProductId = orderline.product.id === payload.product.id
          const isExistingOrderlineByItemId =
            Boolean(payload.item?.id) && orderline.item?.id === payload.item?.id

          if (
            shouldRemoveOrderline &&
            isExistingOrderlineByProductId &&
            (isExistingOrderlineByItemId || !payload.item?.id)
          ) {
            shouldRemoveOrderline = false
            return []
          }

          if (index === extraOrderlines.length - 1) {
            return [orderline, ...orderlineInput]
          }

          return [orderline]
        },
      )

      handoverAdapter.updateOne(state.activeHandovers, {
        id: payload.handoverId,
        changes: {
          extraOrderlines: !current.extraOrderlines.length ? orderlineInput : updatedOrderlines,
        },
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createHandover.pending, (state) => {
      state.submitting = true
      state.error = undefined
    })
    builder.addCase(createHandover.fulfilled, (state, { payload }) => {
      state.submitting = false
      state.error = undefined
      handoverAdapter.addOne(state.activeHandovers, {
        id: payload.id,
        handover: payload,
        selectedItems: {},
        itemsAdded: [],
        itemComments: {},
        extraOrderlines: [],
      })
    })
    builder.addCase(createHandover.rejected, (state, action) => {
      state.submitting = false
      state.error = action.error
    })
    builder.addCase(refreshHandover.pending, (state) => {
      state.submitting = true
      state.error = undefined
    })
    builder.addCase(refreshHandover.fulfilled, (state, { payload: handover }) => {
      state.submitting = false
      state.error = undefined
      const handoverState = handoverAdapter
        .getSelectors()
        .selectById(state.activeHandovers, handover.id)
      if (!handoverState) return
      const itemsAdded = [...handoverState.itemsAdded]
      const removedItems = difference(handoverState.handover.expectedItems, handover.expectedItems)
      removedItems.forEach((item) => {
        if (handoverState.selectedItems[item.id]) {
          itemsAdded.push(item)
        }
      })
      handoverAdapter.updateOne(state.activeHandovers, {
        id: handover.id,
        changes: {
          handover,
          itemsAdded,
        },
      })
    })
    builder.addCase(refreshHandover.rejected, (state, action) => {
      state.submitting = false
      state.error = action.error
    })

    builder.addCase(concludeHandover.pending, (state) => {
      state.submitting = true
      state.error = undefined
    })
    builder.addCase(concludeHandover.fulfilled, (state, action) => {
      state.submitting = false
      state.error = undefined
      handoverAdapter.removeOne(state.activeHandovers, action.payload.id)
    })
    builder.addCase(concludeHandover.rejected, (state, action) => {
      state.submitting = false
      state.error = action.error
    })
  },
})
export const handoverSelector = handoverAdapter.getSelectors<{ handover: State }>(
  (state) => state.handover.activeHandovers,
)
export default slice.reducer
export const {
  updateHandoverState,
  addItemToHandedOverItems,
  toggleItem,
  updateItemComment,
  removeHandoverState,
  createHandoverState,
  toggleExtraOrderlines,
} = slice.actions
