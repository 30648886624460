import { Box, Button, type SpaceProps } from '@chakra-ui/react'
import React, { type ReactNode } from 'react'

interface Props {
  name: string
  id: string
  fullWidth?: boolean
  disabled?: boolean
  children: ReactNode
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const UploadButton = ({
  onChange,
  name,
  fullWidth = true,
  children,
  id,
  disabled,
  ...rest
}: Props & SpaceProps) => {
  return (
    <Box as="label" width="100%" cursor="pointer" htmlFor={id} {...rest}>
      <input
        hidden
        accept="image/*"
        id={id}
        name={name}
        onChange={onChange}
        disabled={disabled}
        type="file"
        capture="environment"
      />
      <Button
        as="span"
        mb="16px"
        mt="16px"
        {...(fullWidth ? { width: '100%' } : null)}
        variant="outline"
        colorScheme="purple"
        isDisabled={disabled}
      >
        {children}
      </Button>
    </Box>
  )
}

export default UploadButton
