import type { SupportedCities, SupportedCountries } from '@wanda-space/types'

export function formatCountry(country: SupportedCountries | 'NO' | 'SE') {
  const countryLabels: Record<SupportedCountries, string> = {
    NO: 'Norway',
    SE: 'Sweden',
  }
  return countryLabels[country]
}

export function formatCity(
  city:
    | SupportedCities
    | 'NO_OSLO'
    | 'NO_STAVANGER'
    | 'SE_STOCKHOLM'
    | 'NO_TRONDHEIM'
    | 'SE_GOTHENBURG',
) {
  const cityLabels: Record<SupportedCities, string> = {
    NO_OSLO: 'Oslo',
    NO_STAVANGER: 'Stavanger',
    SE_STOCKHOLM: 'Stockholm',
    NO_TRONDHEIM: 'Trondheim',
    SE_GOTHENBURG: 'Gothenburg',
  }
  return cityLabels[city]
}

export const formatCrew = (crew?: Record<string, string>[]) =>
  crew ? crew.map((person) => person.name || person.email).join(', ') : 'no crew'
