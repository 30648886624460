import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'

export enum UserRole {
  ADMIN = 'admin',
  TECH = 'tech',
  OPS_ADMIN = 'ops-admin',
  OPS_OPERATOR = 'ops-operator',
  OPS_EXTERNAL_DRIVER = 'ops-external-driver',
  CUSTOMER_SUPPORT = 'customer-support',
  PRODUCT_ADMIN = 'product-admin',
  OPS_WAREHOUSE = 'ops-warehouse',
  OPS_INTERNAL_DRIVER = 'ops-internal-driver',
}

export function useRoles(): UserRole[] {
  const { user, getAccessTokenSilently } = useAuth0()
  return (
    useQuery(
      ['roles', user?.sub],
      async () => {
        const accessToken = await getAccessTokenSilently()
        return jwtDecode<{ permissions?: string[] }>(accessToken)?.permissions as UserRole[]
      },
      { suspense: true, enabled: !!user?.sub },
    ).data ?? []
  )
}

/**
 * This is a hook that can be used to check if the current user has any of the required roles.
 * It will require the user to have **one of** the roles listed in the array, not all of them.
 * See also hoc/requiredRoles.
 */
export function useRequiredRoles(requiredRoles: UserRole[]): boolean {
  const roles = useRoles()
  return requiredRoles.some((role) => roles?.includes(role))
}
