import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import { fetchCurrentAccount } from '../api-client/routes/accounts'

export function useCurrentAccount() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  return useQuery(
    ['current-account'],
    async () => {
      const token = await getAccessTokenSilently()
      return fetchCurrentAccount(token)
    },
    { suspense: true, refetchOnMount: false, enabled: isAuthenticated },
  )
}
