import { type ChakraTheme, extendTheme } from '@chakra-ui/react'
import { colors } from '@wanda-space/noelle'

import { alertTheme } from './alert'

const _theme: Partial<ChakraTheme> = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },
  styles: {
    global: {
      body: {
        margin: 0,
        fontFamily: '-apple-system, "Helvetica Neue", sans-serif',
        fontSmooth: 'antialiased',
        background: 'gray.100',
        _dark: {
          background: 'gray.800',
        },
      },
      'html, body, #root': {
        minHeight: '100%',
        height: '100%',
      },
    },
  },
  colors: {
    ...colors,
    purple: {
      '50': '#EEEDF7',
      '100': '#CFCDE9',
      '200': '#B0ADDC',
      '300': '#928DCE',
      '400': '#736DC0',
      '500': '#544DB2',
      '600': '#433E8E',
      '700': '#322E6B',
      '800': '#221F47',
      '900': '#110F24',
    },
    purpleAlpha: {
      '50': 'rgba(84, 77, 178, 0.04)',
      '100': 'rgba(84, 77, 178, 0.06)',
      '200': 'rgba(84, 77, 178, 0.08)',
      '300': 'rgba(84, 77, 178, 0.16)',
      '400': 'rgba(84, 77, 178, 0.24)',
      '500': 'rgba(84, 77, 178, 0.36)',
      '600': 'rgba(84, 77, 178, 0.48)',
      '700': 'rgba(84, 77, 178, 0.64)',
      '800': 'rgba(84, 77, 178, 0.80)',
      '900': 'rgba(84, 77, 178, 0.92)',
    },
  },
  components: {
    Alert: alertTheme,
    Badge: { defaultProps: { colorScheme: 'purple' } },
    Button: {
      baseStyle: { borderRadius: '8px' },
      sizes: { md: { paddingTop: '16px', paddingBottom: '16px' } },
      defaultProps: { colorScheme: 'purple' },
    },
    Card: {
      baseStyle: {
        padding: '4',
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: '8px',
        maxW: '100%',
        background: 'white',
        _dark: {
          background: 'gray.800',
          borderColor: 'gray.600',
        },
      },
    },
    CircularProgress: {
      baseStyle: { filledTrack: { bg: 'purple.700' }, track: { bg: 'purple.300' } },
    },
    Progress: { defaultProps: { colorScheme: 'purple' } },
    Link: { baseStyle: { color: 'purple.500', _dark: { color: 'purple.100' } } },
    Input: { defaultProps: { focusBorderColor: 'purple.300' } },
    Tabs: { defaultProps: { colorScheme: 'purple' } },
    Tag: { defaultProps: { colorScheme: 'purple' } },
    Textarea: { defaultProps: { focusBorderColor: 'purple.300' } },
    Switch: { defaultProps: { colorScheme: 'purple' } },
    Checkbox: { defaultProps: { colorScheme: 'purple' } },
    Select: { defaultProps: { colorScheme: 'purple' } },
    Table: {
      baseStyle: {
        border: '1px solid',
        borderColor: 'gray.200',
        borderRadius: '8px',
        _dark: {
          borderColor: 'gray.600',
        },
      },
    },
  },
}

export const theme = extendTheme(_theme)

export const selectTheme = (selectTheme: any) => ({
  ...selectTheme,
  colors: {
    ...selectTheme.colors,
    primary25: theme.colors.purple[300],
    primary75: theme.colors.purple[500],
    primary: theme.colors.purple[800],
    neutral10: theme.colors.gray[100],
    neutral20: theme.colors.gray[200],
    neutral30: theme.colors.gray[300],
    neutral40: theme.colors.gray[400],
    neutral50: theme.colors.gray[500],
    neutral60: theme.colors.gray[600],
    neutral70: theme.colors.gray[700],
    neutral80: theme.colors.gray[800],
    neutral90: theme.colors.gray[900],
  },
})
