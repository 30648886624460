import React from 'react'

import { Box, Text } from '@chakra-ui/react'
import type { AdminListingResponseDto } from '@wanda-space/types'

export const ListingInformation = ({ listing }: { listing: AdminListingResponseDto }) => {
  return (
    <>
      <Box>
        <Text as="h2" fontSize="x-large" fontWeight="semibold">
          #{listing.simpleId} - {listing.name}
        </Text>
      </Box>
    </>
  )
}
