import { captureException } from '@sentry/react'
import { v4 as uuid } from 'uuid'

import { baseUrl } from './config'
import { ApiError } from './error'
import { getOrCreateSessionId } from './session-id'

type Options = {
  baseUrl?: string
  url: string
  params?: URLSearchParams
  headers?: Record<string, string | undefined>
  signal?: AbortSignal
  token?: string | undefined
} & (
  | {
      method: 'GET'
      body?: undefined
    }
  | {
      method: 'POST'
      body: unknown
    }
  | {
      method: 'PUT'
      body: unknown
    }
  | {
      method: 'DELETE'
      body?: unknown
    }
  | {
      method: 'PATCH'
      body?: unknown
    }
)

export async function request<ResponseType>(options: Options): Promise<ResponseType> {
  if (options.url.includes('?')) {
    captureException(
      new Error('Do not include query parameters in the url, use the params option instead'),
      { extra: { url: options.url } },
    )
  }
  const url = `${options.baseUrl ? options.baseUrl : baseUrl}/${options.url}${
    options.params ? `?${options.params}` : ''
  }`.replace(/([^:])\/\//g, '$1/')
  const response = await fetch(url, {
    method: options.method,
    credentials: 'same-origin',
    body: options.body
      ? options.body instanceof FormData
        ? options.body
        : JSON.stringify(options.body)
      : undefined,
    signal: options.signal,
    // @ts-ignore ---
    headers: {
      Accept: options.headers?.accept || 'application/json',
      Authorization: options.token ? `Bearer ${options.token}` : undefined,
      Credentials: 'same-origin',
      ...(options.body instanceof FormData
        ? {}
        : { 'Content-Type': options.headers?.contentType || 'application/json' }),
      'x-wanda-trace-id': uuid(),
      'x-wanda-session-id': getOrCreateSessionId(),
      'x-app-version': VERSION,
    },
  })
  if (!response.ok) {
    const error = new ApiError(url, options, response, await response.json().catch(() => ({})))
    if (error.shouldReportToSentry()) {
      captureException(error, {
        fingerprint: error.fingerprint,
        extra: {
          url,
          method: options.method,
          status: response.status,
        },
      })
    }
    throw error
  }

  // using this instead of response.json cause we have endpoints not returning anything
  const text = await response.text()
  if (options.headers?.contentType && options.headers?.accept === 'text/csv') {
    return text as any
  }
  return text.length ? JSON.parse(text) : null
}

export async function requestBlob(options: Omit<Options, 'body'>): Promise<Blob> {
  const url = `${options.baseUrl ? options.baseUrl : baseUrl}/${options.url}${
    options.params ? `?${options.params}` : ''
  }`.replace(/([^:])\/\//g, '$1/')
  const response = await fetch(url, {
    method: options.method,
    credentials: 'same-origin',
    signal: options.signal,
    // @ts-ignore ---
    headers: {
      Authorization: options.token ? `Bearer ${options.token}` : undefined,
      Credentials: 'same-origin',
      'x-wanda-trace-id': uuid(),
      'x-wanda-session-id': getOrCreateSessionId(),
    },
  })
  if (!response.ok) {
    const error = new ApiError(url, options, response)
    if (error.shouldReportToSentry()) {
      captureException(error, {
        fingerprint: error.fingerprint,
        extra: {
          url,
          method: options.method,
          status: response.status,
        },
      })
    }
    throw error
  }
  return response.blob()
}
