import { captureMessage } from '@sentry/react'
import { getEnv } from '@wanda/shared'

const env = getEnv('APP_ENVIRONMENT') ?? 'dev'

const opsUrls = {
  prd: 'https://api.ops.wanda.space',
  stg: 'https://api.ops.stg.wanda.space',
  dev: 'https://api.ops.dev.wanda.space',
}
export const opsBaseUrl =
  process.env.NODE_ENV === 'production' ? opsUrls[env as keyof typeof opsUrls] : '/ops'

if (!opsBaseUrl) {
  captureMessage('Missing ops api base url', { level: 'error' })
}
