import {
  type AdminUpdateUserAddressRequestDto,
  type SupportedCities,
  type UserDto as UserV1Dto,
  isAuth0Id,
  isWandaId,
} from '@wanda-space/types'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { userBaseUrl as baseUrl } from '../config'
import type { UserV2Dto } from '../types'

export function fetchCurrentUser({ token }: { token: string }): Promise<UserV2Dto> {
  return request({
    method: 'GET',
    url: '/v2/users',
    token,
    baseUrl,
  })
}

/* @deprecated use accounts */
export async function updateUserAddress({
  data,
  accountId,
  token,
}: {
  data: AdminUpdateUserAddressRequestDto['address']
  accountId: AdminUpdateUserAddressRequestDto['accountId']
  token: string
}): Promise<UserV1Dto> {
  return request({
    baseUrl,
    method: 'PUT',
    url: '/v1/admin/users/address',
    token,
    body: { accountId, address: { ...data } },
  })
}

/* @deprecated use accounts */
export function updateUserServiceArea({
  id,
  serviceArea,
  token,
}: { id: string; serviceArea: SupportedCities; token: string }) {
  return request({
    baseUrl,
    method: 'PUT',
    url: `/v1/admin/users/${id}/service-area`,
    token,
    body: { serviceArea },
  })
}

export function fetchUsersV2(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
  wandaUsersOnly?: boolean
}): Promise<{ items: UserV2Dto[]; count: number }> {
  const { page, wandaUsersOnly, search, ...rest } = params
  return fetchChunk({
    baseUrl,
    url: wandaUsersOnly ? '/v2/admin/users/wanda' : '/v2/admin/users',
    search: search?.trim(),
    page,
    ...rest,
  })
}

export function fetchUserV2({
  id,
  token,
}: {
  id: string /* auth0Id or wanda id */
  token: string
}): Promise<UserV2Dto> {
  if (isAuth0Id(id)) {
    const params = new URLSearchParams({
      id,
    })
    return request<UserV2Dto>({
      method: 'GET',
      baseUrl,
      url: '/v2/admin/users/by-auth0-id',
      token,
      params,
    })
  }
  if (isWandaId(id)) {
    return request<UserV2Dto>({
      method: 'GET',
      baseUrl,
      url: `/v2/admin/users/${id}`,
      token,
    })
  }
  throw new Error(`Invalid id ${id}`)
}
