export enum OrderPickupActions {
  DEVIATE_ORDER = 'DEVIATE_ORDER',
  ITEMS_HANDOVER_COMPLETED = 'ITEMS_HANDOVER_COMPLETED',
  ITEMS_PLACED_IN_QUARANTINE = 'ITEMS_PLACED_IN_QUARANTINE',
  ITEMS_STORED = 'ITEMS_STORED',
  STATE_TO_PAYMENT_AUTHORISED = 'STATE_TO_PAYMENT_AUTHORISED',
}

export enum OrderDeliveryActions {
  DEVIATE_ORDER = 'DEVIATE_ORDER',
  ITEMS_ON_THE_ROAD = 'ITEMS_ON_THE_ROAD',
  ITEMS_HANDED_OVER = 'ITEMS_HANDED_OVER',
  STATE_TO_PAYMENT_AUTHORISED = 'STATE_TO_PAYMENT_AUTHORISED',
}

export interface OrderPickupPayload {
  action: OrderPickupActions | OrderDeliveryActions
}
