import { useAuth0 } from '@auth0/auth0-react'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@chakra-ui/react'
import { useFeatureFlags } from '@wanda/shared/src/hooks/useFeatureFlags'
import React, { lazy, Suspense } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import Loader from 'components/ui/Loader'
import { useLogout } from 'hooks/useCurrentUser'
import ItemLocationVinden from 'routes/ItemLocation/ItemLocationVinden'
import { Landing } from 'routes/Landing/Landing'
import Login from 'routes/Login'

const Logout = lazy(() => import('routes/Logout/Logout'))
const Users = lazy(() => import('routes/Users/Users'))
const HandoverRedirector = lazy(() => import('routes/Handover/HandoverRedirector'))
const OrderHandover = lazy(() => import('routes/Handover/OrderHandover'))
const InboundHandover = lazy(() => import('routes/Handover/InboundHandover'))
const Inbound = lazy(() => import('routes/Inbound/Inbound'))
const ItemLocation = lazy(() => import('routes/ItemLocation/ItemLocation'))
const BulkUpdateLocation = lazy(() => import('routes/ItemLocation/BulkUpdateLocation'))
const OrderList = lazy(() => import('routes/OrderList'))
const OrderView = lazy(() => import('routes/OrderDetails/OrderPage'))
const Outbound = lazy(() => import('routes/Outbound/Outbound'))
const OutboundPickByOrderView = lazy(() => import('routes/Outbound/PickByOrderView'))
const Picking = lazy(() => import('routes/Picking/Picking'))
const PickingInWarehouse = lazy(() => import('routes/Picking/PickingInWarehouse'))
const PickingInWarehouseHandover = lazy(() => import('routes/Handover/PickingInWarehouseHandover'))
const QrCodes = lazy(() => import('routes/QrCodes'))
const RouteDetails = lazy(() => import('routes/RouteDetails'))
const RouteList = lazy(() => import('routes/RouteList'))
const ServiceOutbound = lazy(() => import('routes/ServiceOutbound/ServiceOutbound'))
const ServiceOutboundHandover = lazy(() => import('routes/Handover/ServiceOutboundHandover'))
const Inventory = lazy(() => import('routes/ItemLocation/Inventory'))
const CreateConsignment = lazy(() => import('routes/Consignment/CreateConsignment'))
const ConsignmentView = lazy(() => import('routes/Consignment/Consignment'))
const InboundWarehouse = lazy(() => import('routes/InboundWarehouse/InboundWarehouse'))
const PutawayWarehouse = lazy(() => import('routes/PutawayWarehouse/PutawayWarehouse'))
const HubDashboard = lazy(() => import('routes/Hub/HubDashboard'))
const ServiceInbound = lazy(() => import('routes/ServiceInbound/ServiceInbound'))
const ServiceInboundHandover = lazy(() => import('routes/Handover/ServiceInboundHandover'))

function RequireAuth() {
  const { isLoading, isAuthenticated, error } = useAuth0()
  const { logout } = useLogout()
  const location = useLocation()

  if (isLoading) {
    return <Loader fullHeight />
  }
  if (error) {
    return (
      <Modal isOpen={true} onClose={() => logout()}>
        <ModalContent>
          <ModalHeader id="alert-dialog-title">Authentication error</ModalHeader>
          <ModalBody>{error.message}</ModalBody>
          <ModalFooter>
            <Button onClick={() => logout()}>Logout</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return <Outlet />
}

export const AppRoutes = () => {
  const { featureFlags } = useFeatureFlags()
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route element={<RequireAuth />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <Landing />
            </Suspense>
          }
        />

        <Route
          path="/orders"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <OrderList />
            </Suspense>
          }
        />
        <Route
          path="/routes"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <RouteList />
            </Suspense>
          }
        />
        <Route
          path="/routes/:routeId"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <RouteDetails />
            </Suspense>
          }
        />
        {featureFlags.ENABLE_BRIDGE_PUT_AWAY && (
          <Route
            path="/item/location"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <ItemLocation />
              </Suspense>
            }
          />
        )}
        {featureFlags.ENABLE_BRIDGE_PUT_AWAY && (
          <Route
            path="/item/location/vinden"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <ItemLocationVinden />
              </Suspense>
            }
          />
        )}
        {featureFlags.ENABLE_BRIDGE_PUT_AWAY && (
          <Route
            path="/item/location/:warehouseId"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <ItemLocation />
              </Suspense>
            }
          />
        )}
        {featureFlags.ENABLE_BRIDGE_PUT_AWAY && (
          <Route
            path="/item/:itemId/location/:warehouseId"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <ItemLocation />
              </Suspense>
            }
          />
        )}
        {featureFlags.ENABLE_BRIDGE_PUT_AWAY && (
          <Route
            path="/item/:itemId/location"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <ItemLocation />
              </Suspense>
            }
          />
        )}
        <Route
          path="/orders/:orderId/qr"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <QrCodes />
            </Suspense>
          }
        />
        <Route
          path="/orders/:orderId/*"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <OrderView />
            </Suspense>
          }
        />
        <Route
          path="/orders/handover/:handoverId"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <OrderHandover />
            </Suspense>
          }
        />
        <Route
          path="/handover/:handoverId"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <HandoverRedirector />
            </Suspense>
          }
        />

        {featureFlags.ENABLE_BRIDGE_PICKING && (
          <Route
            path="/picking"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <Picking />
              </Suspense>
            }
          />
        )}
        {featureFlags.ENABLE_BRIDGE_PICKING_R1 && (
          <>
            <Route
              path="/picking-in-warehouse"
              element={<Navigate to="/picking-in-warehouse/today" />}
            />
            <Route
              path="/picking-in-warehouse/handover/:handoverId"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <PickingInWarehouseHandover />
                </Suspense>
              }
            />
            <Route
              path="/picking-in-warehouse/:range"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <PickingInWarehouse />
                </Suspense>
              }
            />
          </>
        )}
        {featureFlags.ENABLE_BRIDGE_PICKING_R1 && (
          <>
            <Route
              path="/service-outbound"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <ServiceOutbound />
                </Suspense>
              }
            />
            <Route
              path="/service-outbound/handover/:handoverId"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <ServiceOutboundHandover />
                </Suspense>
              }
            />
          </>
        )}

        {featureFlags.ENABLE_OUTBOUND && (
          <>
            <Route path="/outbound" element={<Navigate to="/outbound/today" />} />
            <Route
              path="/outbound/:range"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <Outbound />
                </Suspense>
              }
            />
            <Route
              path="/outbound/:range/pick-by-order"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <OutboundPickByOrderView />
                </Suspense>
              }
            />
          </>
        )}
        {featureFlags.ENABLE_INBOUD_TRIAGE && (
          <>
            <Route
              path="/inbound"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <Inbound />
                </Suspense>
              }
            />
            <Route
              path="/inbound/handover/:handoverId"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <InboundHandover />
                </Suspense>
              }
            />
          </>
        )}
        {featureFlags.ENABLE_BULK_LOCATION_UPDATE && (
          <Route
            path="item/location/bulk"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <BulkUpdateLocation />
              </Suspense>
            }
          />
        )}
        {featureFlags.ENABLE_INVENTORY_VIEW && (
          <Route
            path="item/inventory"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <Inventory />
              </Suspense>
            }
          />
        )}
        {featureFlags.ENABLE_CONSIGNMENTS && (
          <>
            <Route
              path="/consignment/create/:consignmentId"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <CreateConsignment />
                </Suspense>
              }
            />
            <Route
              path="/consignment/:consignmentId"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <ConsignmentView />
                </Suspense>
              }
            />
            <Route
              path="/inbound-warehouse"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <InboundWarehouse />
                </Suspense>
              }
            />
          </>
        )}
        {featureFlags.ENABLE_PUTWAY_WAREHOUSE && (
          <Route
            path="/putaway-warehouse"
            element={
              <Suspense fallback={<Loader fullHeight />}>
                <PutawayWarehouse />
              </Suspense>
            }
          />
        )}
        <Route
          path="/hub"
          element={
            <Suspense fallback={<Loader fullHeight />}>
              <HubDashboard />
            </Suspense>
          }
        />
        {featureFlags.ENABLE_SERVICE_INBOUND && (
          <>
            <Route
              path="/service-inbound/handover/:handoverId"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <ServiceInboundHandover />
                </Suspense>
              }
            />
            <Route
              path="/service-inbound"
              element={
                <Suspense fallback={<Loader fullHeight />}>
                  <ServiceInbound />
                </Suspense>
              }
            />
          </>
        )}
        <Route path="/users/:id" element={<Users />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}
