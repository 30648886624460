import type { SupportedCities } from 'consts'

export enum RouteStopType {
  DELIVERY = 'DELIVERY',
  WAREHOUSE = 'WAREHOUSE',
  PICK_UP = 'PICK_UP',
  BREAK = 'BREAK',
}

export type RouteStopTasks = {
  load?: string[]
  dropOff?: string[]
  charging?: string
  break?: string
  washing?: string
}

export interface RouteStopAddress {
  street: string
  city: string
  postalCode: string
  countryCode: string
}

export type RouteStopModel = {
  id: string
  number: number
  stopType: RouteStopType
  orderId?: string
  orderSimpleId?: string
  name: string
  tasks: RouteStopTasks
  address: RouteStopAddress
  plannedArrival: string
  stopDuration: number
  driveDuration?: number
  expectedTimeSlot: string
  etaTimeRange?: string
  serviceLevel?: string
  floorLevel?: string
  elevator: boolean
  notes?: string
  startedAt?: string
  finishedAt?: string
  isCompleted: boolean
}

export type Crew = {
  id: string
  name: string
  email: string
}

export type RouteModel = {
  id: string
  name: string
  city: SupportedCities
  serviceDate: string
  stops: RouteStopModel[]
  crew: Crew[]
  startAt: string
  endAt: string
}
