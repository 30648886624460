import { ImagePreviewMode, ImageViewer } from '@wanda-space/noelle'
import type { AccountId } from '@wanda-space/types'
import { useListingImages } from '@wanda/shared'
import React from 'react'

export const ListingImages = ({
  listingId,
  sellerAccountId,
}: { listingId: string; sellerAccountId: AccountId }) => {
  const images = useListingImages(
    { listingId: listingId, sellerAccountId },
    { enabled: !!listingId },
  )

  const imageUrls = images.data?.items.map((image) => image.url) ?? []

  return imageUrls.length ? (
    <ImageViewer src={imageUrls[0]} previewMode={ImagePreviewMode.slider} imageUrls={imageUrls} />
  ) : null
}
