import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { useCurrentUser } from '@wanda/shared'

import { fetchRoutes } from 'api/routes/routes'
import { useAppSelector } from 'hooks/useAppSelector'
import type { RouteModel } from 'interface'

export function useRoutes(options?: {
  refetchOnMount?: boolean
  enabled?: boolean
  useErrorBoundary?: boolean
  userId?: string
}) {
  const { getAccessTokenSilently } = useAuth0()
  const { date, city } = useAppSelector((state) => state.filters)
  const { page } = useAppSelector((state) => state.routes)
  const query = {
    orderBy: 'name',
    orderDir: 'ASC' as const,
    itemsPerPage: 100,
    page,
    search: undefined,
    filters: {
      city: city,
      service_date: date,
    },
  }
  return useQuery<{ items: RouteModel[]; count: number }, { body?: object }>(
    ['routes', query],
    async () => {
      const token = await getAccessTokenSilently()
      return await fetchRoutes({ token, ...query })
    },
    { staleTime: 0, ...options, retry: false },
  )
}

export function useRoutesForCurrentUser(options?: {
  refetchOnMount?: boolean
  enabled?: boolean
  useErrorBoundary?: boolean
}) {
  const { getAccessTokenSilently } = useAuth0()

  const user = useCurrentUser()
  const query = {
    orderBy: 'name',
    orderDir: 'ASC' as const,
    itemsPerPage: 100,
    page: 0,
    search: undefined,
    filters: {
      inProgress: true,
      crew: user.data?.email,
    },
  }
  return useQuery<{ items: RouteModel[]; count: number }, { body?: object }>(
    ['routes', user.data?.id],
    async () => {
      const token = await getAccessTokenSilently()
      return await fetchRoutes({ token, ...query })
    },
    { ...options },
  )
}
