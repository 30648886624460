import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import { getWarehouseLocations } from '../api-client/routes/warehouses'

export function useWarehouseLocations(
  params: {
    itemsPerPage?: number
    warehouseId?: string
    search?: string
    filters: Record<string, string[] | string | undefined>
    includeDeprecated?: boolean
  },
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['warehouse-locations', JSON.stringify(params)],
    async () => {
      const token = await getAccessTokenSilently()
      return (
        await getWarehouseLocations({
          warehouseId: undefined,
          search: undefined,
          ...params,
          token,
        })
      ).items
    },
    { staleTime: 1000 * 60 * 10, ...options },
  )
}
