import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import { fetchInternalImages } from '../api-client/routes/images'
export const INTERNAL_IMAGE_QUERY_KEY = 'internal-images'

export function useInternalImages({ items, handover }: { items?: string[]; handover?: string }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  return useQuery(
    [INTERNAL_IMAGE_QUERY_KEY, { items, handover }],
    async () =>
      await fetchInternalImages({
        filters: { items, handover },
        token: await getAccessTokenSilently(),
      }),
    { enabled: isAuthenticated && ((items && items?.length > 0) || !!handover) },
  )
}
