import React from 'react'
import { sentenceCase } from 'sentence-case'

import type { HandoverPossessor } from '../types/handover'

export function Possessor({ possessor }: { possessor: HandoverPossessor }) {
  return (
    <span title={`${sentenceCase(possessor.type)} with id ${possessor.id}`}>
      {sentenceCase(possessor.type)}
    </span>
  )
}
