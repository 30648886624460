import type { ExpectedItems } from '@wanda-space/ops-types'
import { omit } from 'ramda'

import {
  type ConcludeHandoverDto,
  type CreateHandoverDto,
  type HandoverDto,
  HandoverState,
} from '../../types/handover'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { opsBaseUrl } from '../config'

export const fetchHandover = async ({ token, id }: { token: string; id: string }) => {
  return await request<HandoverDto>({
    method: 'GET',
    url: `/v1/handovers/${id}/`,
    token,
    baseUrl: opsBaseUrl,
  })
}
export async function fetchHandovers(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ count: number; items: HandoverDto[] }> {
  return await fetchChunk({
    ...params,
    search: params.search?.trim(),
    baseUrl: opsBaseUrl,
    url: '/v1/handovers/',
    page: params.page === 0 ? 1 : params.page,
  })
}

export const fetchHandoversByOrder = async ({
  orderId,
  token,
}: {
  orderId: string
  token: string
}) => {
  const response = await request<HandoverDto[]>({
    method: 'GET',
    token,
    baseUrl: opsBaseUrl,
    url: `/v1/handovers/by-related-order/${orderId}/`,
  })
  if (Array.isArray(response)) {
    return { items: response, count: response.length }
  }
  return response
}

export const fetchHandoversByItem = async ({
  itemId,
  token,
}: {
  itemId: string
  token: string
}) => {
  const response = await request<HandoverDto[]>({
    method: 'GET',
    token,
    baseUrl: opsBaseUrl,
    url: `/v1/handovers/by-item/${itemId}/`,
  })
  if (Array.isArray(response)) {
    return { items: response, count: response.length }
  }
  return response
}

export const createHandover = async ({
  token,
  body,
}: {
  token: string
  body: CreateHandoverDto
}) => {
  const payload: any = body
  return await request<HandoverDto>({
    method: 'POST',
    url: '/v1/handovers/',
    token,
    body: {
      state: HandoverState.OPEN,
      ...omit(['storageItems'], body),
      expectedItems: body.storageItems,
      countryCode: payload.city.split('_')[0],
    },
    baseUrl: opsBaseUrl,
  })
}
export const concludeHandover = async ({
  token,
  body,
  id,
}: {
  token: string
  body: ConcludeHandoverDto
  id: string
}) => {
  return await request<HandoverDto>({
    method: 'POST',
    url: `/v1/handovers/${id}/conclude/`,
    token,
    body,
    baseUrl: opsBaseUrl,
  })
}
export const refreshHandover = async ({
  token,
  id,
  expectedItems,
}: {
  token: string
  id: string
  expectedItems: ExpectedItems
}) => {
  return request<HandoverDto>({
    method: 'PATCH',
    token,
    baseUrl: opsBaseUrl,
    url: `/v1/handovers/${id}/`,
    body: { expectedItems },
  })
}
