export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

type SortStringNumberProps<T> = {
  direction: SortDirection
  orderBy: keyof T
  list: T[]
}

export const sortByNameOrNumber = <T extends { [key: string]: any }>({
  direction,
  orderBy,
  list,
}: SortStringNumberProps<T>): T[] => {
  return list.slice().sort(byText((listItem) => listItem[orderBy], direction)) as T[]
}

const byText =
  <T>(getTextProperty: (object: T) => string, direction: SortDirection) =>
  (objectA: T, objectB: T) => {
    const upperA = getTextProperty(objectA).toString().toUpperCase()
    const upperB = getTextProperty(objectB).toUpperCase()
    return (
      (upperA === upperB ? 0 : upperA < upperB ? -1 : 1) *
      (direction === SortDirection.ASC ? -1 : 1)
    )
  }
