import type {
  AdminFeatureFlagResponseDto,
  CreateFeatureFlagRequestDto,
  FeatureFlagResponseDto,
  PaginatedResponseDto,
  UpdateFeatureFlagRequestDto,
} from '@wanda-space/types'

import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'

export async function getFeatureFlagForCurrentUser({
  token,
}: {
  token: string
}): Promise<Record<string, boolean>> {
  return (
    await request<FeatureFlagResponseDto>({
      url: '/feature-flags',
      method: 'GET',
      token,
    })
  ).flags
}

export async function getFeatureFlags(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<PaginatedResponseDto<AdminFeatureFlagResponseDto>> {
  return await fetchChunk({
    ...params,
    search: params.search?.trim(),
    url: '/admin/feature-flags',
  })
}

export async function createFeatureFlag({
  body,
  token,
}: {
  body: CreateFeatureFlagRequestDto
  token: string
}) {
  return await request<AdminFeatureFlagResponseDto>({
    url: '/admin/feature-flags/',
    method: 'POST',
    token,
    body,
  })
}

export async function updateFeatureFlag(
  id: string,
  values: UpdateFeatureFlagRequestDto,
  token: string,
) {
  return await request<AdminFeatureFlagResponseDto>({
    url: `/admin/feature-flags/${id}`,
    method: 'PUT',
    token,
    body: values,
  })
}

export async function deleteFeatureFlag({ id, token }: { id: string; token: string }) {
  return await request({
    url: `/admin/feature-flags/${id}`,
    method: 'DELETE',
    token,
  })
}
