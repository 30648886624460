import { request } from '@wanda/shared/src/api-client/api-client'
import { fetchChunk } from '@wanda/shared/src/api-client/chunk'

import { opsBaseUrl as baseUrl } from 'api/config'
import type { RouteEvent, RouteModel } from 'interface'

export const fetchRoute = async ({ token, id }: { token: string; id: string }) => {
  try {
    return await request<RouteModel>({
      method: 'GET',
      url: `/v1/routes/${id}/`,
      baseUrl,
      token,
    })
  } catch (err) {
    console.error(`Cannot fetch route: ${err}`)
    throw err
  }
}

export async function fetchRoutes(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: Record<string, string[] | string | undefined | boolean>
  token: string
}): Promise<{ items: RouteModel[]; count: number }> {
  return fetchChunk({
    url: '/v1/routes/',
    baseUrl,
    ...params,
  })
}

export const postRouteEvent = async ({ token, event }: { token: string; event: RouteEvent }) => {
  try {
    return await request<RouteModel>({
      method: 'POST',
      url: '/v1/routes/events/',
      baseUrl,
      token,
      body: event,
    })
  } catch (err) {
    console.error(`Cannot post route event: ${err}`)
    throw err
  }
}

export const updateRouteStopTiming = async ({
  token,
  id,
  startedAt,
  finishedAt,
}: {
  token: string
  id: string
  startedAt?: string
  finishedAt?: string
}) => {
  try {
    return await request<RouteModel>({
      method: 'POST',
      url: `/v1/routes/stops/${id}/time/`,
      baseUrl,
      token,
      body: {
        ...(startedAt && { startedAt }),
        ...(finishedAt && { finishedAt }),
      },
    })
  } catch (err) {
    console.error(`Cannot update route stop timing: ${err}`)
    throw err
  }
}

export const finishRoute = async ({ token, id }: { token: string; id: string }) => {
  try {
    return await request<RouteModel>({
      method: 'POST',
      url: `/v1/routes/${id}/finish/`,
      baseUrl,
      token,
      body: null,
    })
  } catch (err) {
    console.error(`Cannot finish route: ${err}`)
    throw err
  }
}
