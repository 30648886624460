import { captureMessage } from '@sentry/react'

import { getEnv } from '../env'

export const baseUrl = '/api/v1'

const env = getEnv('APP_ENVIRONMENT') as 'prd' | 'dev'

const opsUrls = {
  prd: 'https://api.ops.wanda.space',
  dev: 'https://api.ops.dev.wanda.space',
}
export const opsBaseUrl = process.env.NODE_ENV === 'production' ? opsUrls[env] : '/ops'

if (!opsBaseUrl) {
  captureMessage('Missing ops api base url', { level: 'error' })
}

const userApiUrls = {
  prd: 'https://api.users.wanda.space',
  dev: 'https://api.users.dev.wanda.space',
}
export const userBaseUrl = process.env.NODE_ENV === 'production' ? userApiUrls[env] : '/users'

if (!userApiUrls) {
  captureMessage('Missing user api base url', { level: 'error' })
}
