import type {
  Address,
  CollicareOrderDto,
  WarehouseDto,
  WarehouseLocationDto,
} from '@wanda-space/ops-types'

import type { SupportedCities, SupportedCountries } from '../../consts/enums'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { opsBaseUrl as baseUrl } from '../config'
export interface Warehouse {
  id: string
  name: string
  city: SupportedCities
  country: SupportedCountries
  hub: boolean
}

export type WarehouseWithAddress = Warehouse & { address: Address }

export const getWarehouses = async (params: {
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}) => {
  return fetchChunk<Warehouse>({
    url: '/v1/warehouses/',
    orderBy: 'name',
    orderDir: 'ASC',
    itemsPerPage: 100,
    page: 1,
    baseUrl,
    ...params,
    search: params.search?.trim(),
  })
}

export async function fetchWarehouses(params: {
  filters: ApiFilters | undefined
  token: string
  itemsPerPage: number
  page: number
  search: string | undefined
  orderBy: string
  orderDir: 'ASC' | 'DESC'
}): Promise<{ count: number; items: WarehouseDto[] }> {
  return fetchChunk({
    baseUrl,
    url: 'v1/warehouses/',
    ...params,
    search: params.search?.trim(),
  })
}

export const getWarehouse = async ({ token, id }: { token: string; id: string }) => {
  return await request<Warehouse>({
    method: 'GET',
    url: `/v1/warehouses/${id}/`,
    token,
    baseUrl,
  })
}

export type WarehouseLocation = {
  id: string
  warehouse: string
  location: string
  spot?: string | null
  areaCode?: string | null
  label: string
  type?: string | null
}

export const getWarehouseLocation = async ({ token, id }: { token: string; id: string }) => {
  return await request<WarehouseLocation>({
    method: 'GET',
    url: `/v1/warehouse-locations/${id}/`,
    token,
    baseUrl,
  })
}
export async function fetchWarehouseLocations(params: {
  filters: ApiFilters | undefined
  token: string
  itemsPerPage: number
  page: number
  search: string | undefined
  orderBy: string
  orderDir: 'ASC' | 'DESC'
}): Promise<{ count: number; items: WarehouseLocationDto[] }> {
  return fetchChunk({
    baseUrl,
    url: 'v1/warehouse-locations/',
    ...params,
    search: params.search?.trim(),
  })
}

export const getWarehouseLocations = async ({
  warehouseId,
  includeDeprecated,
  ...params
}: {
  warehouseId: string | undefined
  search: string | undefined
  filters: Record<string, string[] | string | undefined>
  includeDeprecated?: boolean
  token: string
}) => {
  const query = new URLSearchParams()
  if (includeDeprecated) {
    query.append('include-deprecated', 'true')
  }
  return fetchChunk<WarehouseLocation>({
    url: warehouseId ? `/v1/warehouses/${warehouseId}/locations/` : '/v1/warehouse-locations/',
    orderBy: 'name',
    orderDir: 'ASC',
    itemsPerPage: 5000,
    page: 1,
    baseUrl,
    ...params,
    params: query,
    search: params.search?.trim(),
  })
}

export const createCollicareStorageOrder = (
  params: CollicareOrderDto & {
    token: string
  },
) => {
  return request({
    method: 'POST',
    url: '/v1/warehouses/collicare/storage-order/',
    body: {
      itemIds: params.itemIds,
      consignmentIds: params.consignmentIds,
      orderDate: params.orderDate,
      warehouseId: params.warehouseId,
    },
    token: params.token,
    baseUrl,
  })
}

export const createCollicarePickingOrder = (
  params: CollicareOrderDto & {
    token: string
  },
) => {
  return request({
    method: 'POST',
    url: '/v1/warehouses/collicare/picking-order/',
    body: {
      itemIds: params.itemIds,
      consignmentIds: params.consignmentIds,
      orderDate: params.orderDate,
      warehouseId: params.warehouseId,
    },
    token: params.token,
    baseUrl,
  })
}

export function getWarehouseForVisit({
  serviceArea,
  token,
}: { serviceArea: SupportedCities; token: string }) {
  return request<WarehouseWithAddress>({
    method: 'GET',
    url: '/v1/warehouses/visit/',
    baseUrl,
    params: new URLSearchParams({ serviceArea }),
    token,
  })
}
