import { useAuth0 } from '@auth0/auth0-react'
import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { ApiError } from '@wanda/shared'
import {
  fetchImageDetailByImageId,
  fetchImageDetails,
} from '@wanda/shared/src/api-client/routes/items'
import { useInternalImages } from '@wanda/shared/src/hooks/useInternalImages'
import React, { useState, useEffect, type CSSProperties, type MouseEvent } from 'react'

import EmptyImageGreen from 'assets/empty-image-green.svg'
import EmptyImagePurple from 'assets/empty-image-purple.svg'
import Loader from 'components/ui/Loader'
import { MdBrokenImage } from 'react-icons/md'

export type CapturedImageData = {
  itemId: string
  image: string
  isUploading: boolean
}

type ImageRefWithUrl = { imageId?: never; imageUrl: string }
type ImageRefWithId = { imageId: string; imageUrl?: never }
type ImageRefEmpty = { imageId?: never; imageUrl?: never }

type ImageRefProps = ImageRefWithId | ImageRefWithUrl | ImageRefEmpty

type Props = {
  itemId: string
  style?: CSSProperties
  fromOrderItems?: boolean
  allowMissingImageId?: boolean
  size?: string
  onClick?: (e: MouseEvent) => void
  capturedImage?: CapturedImageData
} & ImageRefProps

const ItemImage = ({
  itemId,
  allowMissingImageId = true,
  fromOrderItems,
  size = '140px',
  onClick,
  capturedImage,
  ...rest
}: Props) => {
  const imageUrlFromProps = (rest as ImageRefWithUrl)?.imageUrl
  const [imageUrl, setImageUrl] = useState(imageUrlFromProps ?? '')
  const { getAccessTokenSilently } = useAuth0()
  const { data: internalImages, isInitialLoading: isLoadingInternalImages } = useInternalImages({
    items: [itemId],
  })
  const imageId = (rest as ImageRefWithId)?.imageId

  const { isInitialLoading, data, error } = useQuery(
    imageId ? ['item-image', itemId, imageId] : ['item-image', itemId],
    async () => {
      try {
        const imageId = (rest as ImageRefWithId).imageId
        const token = await getAccessTokenSilently()
        if (imageId) {
          return await fetchImageDetailByImageId(itemId, imageId, token)
        }
        const {
          items: [image],
        } = await fetchImageDetails(itemId, token)
        return image ?? null
      } catch (error: unknown) {
        const apiError = error as ApiError
        if (!(apiError.status && apiError.status() === 404)) {
          throw error
        }
      }
    },
    {
      retry: false,
      enabled: !!itemId && (allowMissingImageId || !!imageId) && !imageUrlFromProps,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  )

  useEffect(() => {
    if (internalImages?.count && internalImages?.count > 0) {
      setImageUrl(internalImages?.items[0].file)
    } else if (!internalImages?.count && !isLoadingInternalImages && data) {
      setImageUrl(data.url)
    }
  }, [data, internalImages])

  if (isLoadingInternalImages || isInitialLoading) {
    return <Loader />
  }

  if (error && !(error instanceof ApiError && error?.status() === 404)) {
    return <MdBrokenImage />
  }

  return imageUrl || capturedImage ? (
    <Box position="relative" onClick={onClick} display="block" height="100%" cursor="pointer">
      {capturedImage?.isUploading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          opacity=".4"
          bg="white"
          position="absolute"
          width="100%"
          height="100%"
        >
          <Spinner color="black" />
        </Flex>
      )}
      <img src={capturedImage ? capturedImage.image : imageUrl} width={size} height={size} alt="" />
    </Box>
  ) : (
    <>
      {fromOrderItems ? (
        <EmptyImagePurple onClick={onClick} width={size} height={size} />
      ) : (
        <EmptyImageGreen onClick={onClick} width={size} height={size} />
      )}
    </>
  )
}

export default ItemImage
