import { useAuth0 } from '@auth0/auth0-react'

import { useQuery } from '@tanstack/react-query'
import type { ConsignmentDto } from '@wanda-space/ops-types'
import { validate } from 'uuid'
import type { ApiFilters } from '../api-client/chunk'
import {
  fetchConsignmentByLocationId,
  fetchConsignments,
  fetchConsignmentsByItem,
} from '../api-client/routes/consignments'

export function isValidConsignmentId(id: string | undefined | null) {
  if (!id) {
    return false
  }
  if (validate(id)) {
    return true
  }
  if (/^#?\d{3}\d+$/.test(id)) {
    return true
  }
  return false
}

export const useConsignment = (
  id: string | undefined | null,
  options?: { refetchOnMount?: boolean; suspense?: boolean; enabled?: boolean },
): ConsignmentDto | undefined => {
  const { getAccessTokenSilently } = useAuth0()
  let filters: ApiFilters = {}

  const query = useQuery(
    ['consignment', id],
    async () => {
      if (!id) {
        throw new Error('Missing consignment id')
      }

      if (validate(id)) {
        filters = { id }
      } else if (/^#\d{3}\d+$/.test(id)) {
        filters = { simpleId: id.replace('#', 'WANDA-') }
      } else if (/^WANDA-?\d{3}\d+$/.test(id)) {
        filters = { simpleId: id }
      } else if (/^\d{3}\d+$/.test(id)) {
        filters = { simpleId: `WANDA-${id}` }
      } else {
        throw new Error(`Invalid consignment id ${id}`)
      }

      const result = await fetchConsignments({
        token: await getAccessTokenSilently(),
        filters,
        page: 1,
        itemsPerPage: 1,
      })
      return result.items[0]
    },
    {
      ...options,
      enabled: (options?.enabled ?? true) && isValidConsignmentId(id),
    },
  )

  return query.data
}

export function useConsignmentsForItem(itemId: string) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['consignment-for-item', itemId],
    async () =>
      (
        await fetchConsignmentsByItem({
          itemId,
          token: await getAccessTokenSilently(),
        })
      ).items,
    { enabled: !!itemId, suspense: true },
  )
}

export function useConsignmentForLocation(locationId: string) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['consignment-for-locaiton', locationId],
    async () =>
      (
        await fetchConsignmentByLocationId({
          locationId,
          token: await getAccessTokenSilently(),
        })
      ).items,
    { enabled: !!locationId, suspense: true },
  )
}
