import { Box, Text } from '@chakra-ui/react'
import { type OptionProps, Select, components } from 'chakra-react-select'
import { propEq } from 'ramda'
import React, { useEffect, useState } from 'react'

import type { WarehouseLocation } from '../api-client/routes/warehouses'
import { useWarehouseLocations } from '../hooks/useWarehouseLocations'
import { selectTheme } from '../styles/theme'

export const WarehouseLocationSelect = (props: {
  name: string
  value: WarehouseLocation | string
  warehouseId: string
  filters?: Record<string, string[] | string | undefined>
  size?: 'sm' | 'md' | 'lg'
  emptyLabel?: string
  includeDeprecated?: boolean
  isDisabled?: boolean
  onChange: (location: WarehouseLocation | null) => void
  onOptionsLoaded?: (locations: WarehouseLocation[]) => void
}) => {
  const [search, setSearch] = useState('')
  const locations = useWarehouseLocations({
    warehouseId: props.warehouseId,
    filters: props.filters || {},
    includeDeprecated: props.includeDeprecated,
    itemsPerPage: 100,
    search,
  })

  useEffect(() => {
    if (locations.data) {
      props.onOptionsLoaded?.(locations.data)
    }
  }, [locations.data, props.onOptionsLoaded])

  const value =
    typeof props.value === 'string' ? locations.data?.find(propEq(props.value, 'id')) : props.value

  return (
    <Select
      isLoading={locations.isInitialLoading}
      isDisabled={props.isDisabled}
      size={props.size}
      isMulti={false}
      placeholder={props.emptyLabel ?? 'Select location'}
      name={props.name}
      aria-label="Select location"
      components={{ Option }}
      isSearchable
      isClearable
      useBasicStyles
      theme={selectTheme}
      onChange={(event) => {
        props.onChange(event)
      }}
      onInputChange={(event: string) => {
        setSearch(event)
      }}
      options={locations.data}
      value={value}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
    />
  )
}

const Option = (props: OptionProps<WarehouseLocation>) => {
  return (
    <components.Option {...props}>
      <Box>
        <Text fontWeight="bold">{props.data.label}</Text>
      </Box>
    </components.Option>
  )
}
