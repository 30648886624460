import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Flex, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { MdEdit } from '@react-icons/all-files/md/MdEdit'
import { useQuery } from '@tanstack/react-query'
import {
  CopyToClipboard,
  FormType,
  OrderBooking,
  ProductCategory,
  Warehouse,
  WarehouseLocation,
  createGoogleFormLink,
} from '@wanda/shared'
import { getServiceOrdersByItemId } from '@wanda/shared/src/api-client/routes/service-orders'
import { format } from 'date-fns'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'
import { sentenceCase } from 'sentence-case'

import { useItem } from 'hooks/useItem'

import ImagePreview from './ImagePreview'
import Loader from './ui/Loader'

const ItemDetails = ({
  id,
  showUpdateLocation = false,
}: {
  id: string
  showUpdateLocation?: boolean
}) => {
  const item = useItem(id)
  const { getAccessTokenSilently } = useAuth0()
  const { data: serviceOrders } = useQuery(
    ['service-order-by-item', item.data?.id],
    async () =>
      getServiceOrdersByItemId({ id: item.data!.id, token: await getAccessTokenSilently() }),
    { enabled: !!item.data },
  )
  const serviceOrder = serviceOrders && serviceOrders.length > 0 ? serviceOrders[0] : undefined
  if (item.isInitialLoading) {
    return <Loader size="xl" />
  }
  if (!item.data) {
    return <>Could not load item</>
  }
  const {
    simpleId,
    name,
    type,
    ownerId,
    opsId,
    state,
    opsType,
    warehouse,
    location,
    pendingOrderId,
    oldOpsId,
    storageProduct,
  } = item.data

  return (
    <Stack p="4">
      <Flex justifyContent="space-between">
        <Box>
          <Text as="h2" fontSize="x-large" fontWeight="semibold">
            #{simpleId} - {type}
          </Text>
          <Text>
            <Text as="span" fontSize="small">
              Name
            </Text>
            <br />
            {name}
          </Text>
        </Box>
        <ImagePreview itemId={id} />
      </Flex>
      <Box>
        <Flex m="-2" flexWrap="wrap">
          {!!serviceOrder && (
            <Text m="2" minW="40%">
              <Text as="span" fontSize="small">
                Service order
              </Text>
              <br />
              <Text as="span">
                {serviceOrder.simpleId}
                <br />
                {serviceOrder.state}({format(new Date(serviceOrder.createdAt), 'dd.MM.yyyy')})
              </Text>
            </Text>
          )}
          <Text m="2" minW="80%">
            <Text as="span" fontSize="small">
              Owner <CopyToClipboard value={ownerId} />
            </Text>
            <br />
            {ownerId}
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              Product
            </Text>
            <br />
            {storageProduct.categoryId && (
              <ProductCategory categoryId={storageProduct.categoryId} />
            )}
            {': '}
            <FormattedMessage id={`${storageProduct.localizationKey}.name`} />
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              State
            </Text>
            <br />
            {sentenceCase(state || '')}
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              OPS type
            </Text>
            <br />
            {opsType || '-'}
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              Warehouse
            </Text>
            <br />
            {warehouse ? <Warehouse id={warehouse.id} /> : '-'}
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              Location
            </Text>
            {showUpdateLocation && (
              <Link
                ml="2"
                fontSize="small"
                as={RouterLink}
                to={{
                  pathname: `/warehouse/${warehouse?.id}/location`,
                  search: `?lookupId=${id}`,
                }}
                onClick={() => {
                  sessionStorage.setItem('navigateBackPath', `/item/${item.data.id}/`)
                }}
              >
                <Icon as={MdEdit} aria-label="Edit" />
              </Link>
            )}
            <br />
            {location ? <WarehouseLocation id={location.id} /> : '-'}
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              Pending order
            </Text>
            <br />
            {pendingOrderId ? (
              <OrderBooking orderId={pendingOrderId} showSimpleId showOrderType />
            ) : (
              '-'
            )}
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              Ops id <CopyToClipboard value={id} />
            </Text>
            <br />
            {opsId}
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              Deprecated ops id {oldOpsId ? <CopyToClipboard value={id} /> : ''}
            </Text>
            <br />
            {oldOpsId || '-'}
          </Text>
          <Text m="2" minW="40%">
            <Text as="span" fontSize="small">
              ID <CopyToClipboard value={id} />
            </Text>
            <br />
            {id}
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <Button
            as="a"
            href={createGoogleFormLink(FormType.ReportItemIssue, { id: id, ownerId })}
            target="_blank"
            rel="noreferrer"
            variant="outline"
            size="sm"
            mt="4"
          >
            Report an issue with the item
          </Button>
        </Flex>
      </Box>
    </Stack>
  )
}

export default ItemDetails
