import type { SerializedError } from '@reduxjs/toolkit'
import type { City, DeliveryTimeSlot, StorageItems } from '@wanda-space/ops-types'
import { format, parseISO } from 'date-fns'

import type { ContactInfoDto, OrderAddressDto } from 'interface'

import { OrderState, type StorageItemType, type SupportedCities } from './consts'

export const formatDate = (date: string | null | undefined, datePattern = 'dd/MM/yyyy') => {
  if (!date) {
    return ''
  }
  const zonedDate = parseISO(date)

  return format(zonedDate, datePattern)
}

export const formatTimeSlot = (timeSlot: DeliveryTimeSlot) => {
  const from = `${timeSlot.startAt.substring(0, 2)}:${timeSlot.startAt.substring(2)}`
  const to = `${timeSlot.endAt.substring(0, 2)}:${timeSlot.endAt.substring(2)}`
  return `${from} - ${to}`
}

export const formatCity = (city: SupportedCities | City | 'all'): string => {
  if (city === 'all') {
    return 'all cities'
  }
  const cityName = city.slice(3)
  return cityName.charAt(0) + cityName.slice(1).toLowerCase()
}

export const typeOfStorageItems = (items: StorageItems, type: StorageItemType) => {
  const oneTypeArray = items.filter((x) => x.type === type)
  return oneTypeArray.length
}
export const getOrderStateLabelColor = (orderState: OrderState) => {
  switch (orderState) {
    case OrderState.PAYMENT_AUTHORISED:
      return 'rgba(196, 255, 121, 1)'
    case OrderState.ORDER_SUBMITTED:
      return 'rgba(255, 193, 121, 1)'
    case OrderState.SHIPMENT_HANDOVER_COMPLETED:
      return 'rgba(121, 215, 255, 1)'
    case OrderState.ORDER_FULFILLED:
      return 'rgba(215, 150, 255, 1)'
    case OrderState.DEVIATED:
      return 'rgba(255, 176, 176, 1)'
    case OrderState.REJECTED:
      return 'rgba(176, 0, 32, 1)'
  }
}

export const googleDirectionsLink = (address: {
  street: string
  city: string
  postalCode: string
}) => {
  const destination = encodeURIComponent(`${address.street}, ${address.postalCode} ${address.city}`)
  return `https://www.google.com/maps/dir/?api=1&dir_action=navigate&travelmode=driving&destination=${destination}`
}

export const formatFullName = (contactInfo: ContactInfoDto) =>
  `${contactInfo.firstName} ${contactInfo.lastName}`

export const formatFullAddress = (address: OrderAddressDto) =>
  `${address.street}, ${address.postalCode} ${address.city}`

export const trimSwedishPhoneNumbers = (number: string | undefined): string => {
  if (number?.includes('+460', 0)) {
    const preDial = number.substring(0, 3)
    const afterDial = number.substring(4)
    const swedishPhoneNumber = preDial + afterDial
    return swedishPhoneNumber
  }
  return number || ''
}

export const throwFromSerialized = (cereal: SerializedError) => {
  const error = new Error(cereal.message)
  error.stack = cereal.stack
  throw error
}

export const isiOs = () => /iPad|iPhone|iPod/.test(navigator.userAgent)
