import { useAuth0 } from '@auth0/auth0-react'
import { type UseQueryOptions, useQuery } from '@tanstack/react-query'

import { fetchUserV2 } from '../api-client/routes/users'
import type { UserV2Dto } from '../api-client/types'

export const useUser = <TError>(
  id: string | undefined,
  options?: Omit<
    UseQueryOptions<Promise<UserV2Dto>, TError, UserV2Dto, string[]>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery<Promise<UserV2Dto>, TError, UserV2Dto, string[]>(
    ['user-v2', id!],
    async () => fetchUserV2({ id: id!, token: await getAccessTokenSilently() }),
    { suspense: true, ...options, enabled: options?.enabled ?? !!id },
  )
}
