import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { ApiError } from '@wanda/shared'
import React, { useState } from 'react'

import { MdBrokenImage } from 'react-icons/md'

import { fetchImageDetails } from '@wanda/shared/src/api-client/routes/items'
import ItemImage from './ItemImage'
import Loader from './ui/Loader'

interface Props {
  itemId: string
  fromOrderItems?: boolean
  imageSize?: string
}

const ImagePreview = ({ itemId, fromOrderItems, imageSize = '140px' }: Props) => {
  const [showImage, setShowImage] = useState(false)
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null)
  const { getAccessTokenSilently } = useAuth0()

  const { isInitialLoading, data, error } = useQuery(
    ['item-images', itemId],
    async () => {
      try {
        const token = await getAccessTokenSilently()
        return await fetchImageDetails(itemId, token)
      } catch (error: unknown) {
        const apiError = error as ApiError
        if (!(apiError.status && apiError.status() === 404)) {
          throw error
        }
      }
    },
    {
      retry: false,
      enabled: !!itemId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  )

  const imageUrls = data?.items?.map((imageDetails) => imageDetails.url) ?? []
  const mainImgUrl = imageUrls[0]

  if (error && !(error instanceof ApiError && error?.status() === 404)) {
    return <MdBrokenImage />
  }

  return isInitialLoading ? (
    <Loader />
  ) : (
    <>
      <ItemImage
        itemId={itemId}
        imageUrl={mainImgUrl}
        size={imageSize}
        onClick={() => setShowImage(true)}
        fromOrderItems={fromOrderItems}
      />
      <Modal
        aria-labelledby="preview-image"
        isOpen={showImage}
        onClose={() => setShowImage(false)}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={4}>
            <>
              <Image src={previewImageUrl ?? mainImgUrl} height="100%" margin="0 auto" />
              {imageUrls && (
                <Flex mt={4} gap={2} flexWrap="wrap">
                  {imageUrls?.map((imageUrl) => (
                    <Box key={imageUrl} w="90px">
                      <Image
                        key={imageUrl}
                        src={imageUrl}
                        height="90"
                        onClick={() => setPreviewImageUrl(imageUrl)}
                      />
                    </Box>
                  ))}
                </Flex>
              )}
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ImagePreview
