import { Box, Text } from '@chakra-ui/react'
import { IoMdCalendar } from '@react-icons/all-files/io/IoMdCalendar'
import { MdLocationCity } from '@react-icons/all-files/md/MdLocationCity'
import { MdPeople } from '@react-icons/all-files/md/MdPeople'
import { MdSchedule } from '@react-icons/all-files/md/MdSchedule'
import { formatCrew } from '@wanda/shared'
import React from 'react'
import { Link } from 'react-router-dom'

import { Card } from 'components/ui/Card/Card'
import { SupportedCities } from 'consts'
import { useRouteEventTracker } from 'hooks/useRouteEventTracker'
import { type RouteModel, routeClickEvent } from 'interface'
import { formatCity, formatDate } from 'utils'

export function RouteSummary({ route }: { route: RouteModel }) {
  const routeEventTracker = useRouteEventTracker()

  return (
    <Card key={route.id} minW="320px" padding="12px 12px 20px" p="0">
      <Link
        to={`/routes/${route.id}`}
        onClick={() => {
          routeEventTracker(routeClickEvent(route))
        }}
      >
        <Text align="center" fontSize="large">
          {route.name}
        </Text>
        <Box display="flex" alignItems="center" marginBottom="4px" marginLeft="20px">
          <Box mr="16px">
            <MdLocationCity fontSize="1.5rem" color="purple.500" />
          </Box>
          <Text align="center">{formatCity(SupportedCities[route.city])}</Text>
        </Box>
        <Box display="flex" alignItems="center" marginBottom="4px" marginLeft="20px">
          <Box mr="16px">
            <IoMdCalendar fontSize="1.5rem" color="purple.500" />
          </Box>
          <Text align="center">{formatDate(route.serviceDate)}</Text>
        </Box>
        <Box display="flex" alignItems="center" marginBottom="4px" marginLeft="20px">
          <Box mr="16px">
            <MdSchedule fontSize="1.5rem" color="purple.500" />
          </Box>
          <Text align="center">
            {formatDate(route.startAt, 'HH:mm')} - {formatDate(route.endAt, 'HH:mm')}
          </Text>
        </Box>
        <Box display="flex" alignItems="center" marginBottom="4px" marginLeft="20px">
          <Box mr="16px">
            <MdPeople fontSize="1.5rem" color="purple.500" />
          </Box>
          <Text align="center">{formatCrew(route.crew)}</Text>
        </Box>
      </Link>
    </Card>
  )
}
