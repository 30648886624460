import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { opsBaseUrl } from '../config'
import type { OpsImage } from '../types'
// require either itemIds or handoverId
type params =
  | {
      token: string
      handoverId: string
      itemIds: string[]
      file: File
    }
  | {
      token: string
      handoverId: string
      itemIds?: undefined
      file: File
    }
  | {
      token: string
      handoverId?: undefined
      itemIds: string[]
      file: File
    }

export const uploadInternalImage = async ({ token, handoverId, itemIds, file }: params) => {
  const form = new FormData()
  form.append('file', file)
  if (handoverId) {
    form.append('handover', handoverId)
  }
  if (itemIds) {
    itemIds.forEach((item) => {
      form.append('items', item)
    })
  }
  return request<OpsImage>({
    method: 'POST',
    url: '/v1/images/',
    body: form,
    token,
    baseUrl: opsBaseUrl,
  })
}

export const fetchInternalImages = async ({
  orderBy = 'created_at',
  orderDir = 'DESC',
  itemsPerPage = 25,
  page = 0,
  search = '',
  ...rest
}: {
  orderBy?: string
  orderDir?: 'ASC' | 'DESC'
  itemsPerPage?: number
  page?: number
  search?: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ items: OpsImage[]; count: number }> =>
  fetchChunk({
    baseUrl: opsBaseUrl,
    url: '/v1/images/',
    orderBy,
    orderDir,
    itemsPerPage,
    page,
    search,
    ...rest,
  })
