import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@chakra-ui/react'
import { setUser } from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { Suspense, useEffect } from 'react'

import Loader from 'components/ui/Loader'

import { NotDefaultAccountModal, UserMissingCityModal } from '@wanda/shared'
import { IntlProvider } from './IntlProvider'
import { AppRoutes } from './Routes'

export default function App() {
  const { user } = useAuth0()
  const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 1000 * 60 } } })

  useEffect(() => {
    if (user) {
      setUser({ id: user.sub, email: user.email })
    }
  }, [user])

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader fullHeight />}>
        <IntlProvider>
          <Box mb="8" height="100%">
            <AppRoutes />
            <UserMissingCityModal />
            <NotDefaultAccountModal />
          </Box>
        </IntlProvider>
      </Suspense>
    </QueryClientProvider>
  )
}
