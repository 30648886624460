import { Card, CardBody, Flex, HStack, Heading, SimpleGrid, Spacer } from '@chakra-ui/react'
import { useRecentUpdates } from '@wanda/shared/src/hooks/useRecentUpdates'
import { isPast } from 'date-fns'
import React, { useMemo } from 'react'

import { UserRole, requireRoles, useCurrentUser } from '@wanda/shared'
import { Header } from 'components/Header'
import { UpdatesCard } from 'components/HelpText/UpdatesCard'
import { RouteSummary } from 'components/RouteSummary'
import { useRoutesForCurrentUser } from 'hooks/useRoutes'
import type { RouteModel } from 'interface'

export function Landing() {
  const currentUser = useCurrentUser()
  const { data: routes } = useRoutesForCurrentUser()
  const [unfinishedRoutes, upcomingRoutes] = useMemo(() => {
    let upcomingRoutes: RouteModel[] = []
    let unfinishedRoutes: RouteModel[] = []
    if (routes) {
      unfinishedRoutes = routes.items.filter((route) => isPast(new Date(route.serviceDate)))
      upcomingRoutes = routes.items.filter((route) => !isPast(new Date(route.serviceDate)))
    }
    return [unfinishedRoutes, upcomingRoutes]
  }, [routes])

  return (
    <>
      <Header title={currentUser.data?.email} />

      <Flex padding={4} gap={4} direction="column">
        <RecentUpdates />
        <Spacer />
        <Heading size="md">Your Upcoming Routes</Heading>
        <HStack spacing={2} overflowX="scroll">
          {upcomingRoutes.length > 0 ? (
            upcomingRoutes.map((route) => <RouteSummary key={route.id} route={route} />)
          ) : (
            <Card minW="320px" padding="12px 12px 20px" p="0">
              <CardBody textAlign="center">No scheduled routes</CardBody>
            </Card>
          )}
        </HStack>
        <Spacer />
        <Heading size="md">Your Unfinished Routes</Heading>
        <HStack spacing={2} overflowX="scroll">
          {unfinishedRoutes.length > 0 ? (
            unfinishedRoutes.map((route) => <RouteSummary key={route.id} route={route} />)
          ) : (
            <Card minW="320px" padding="12px 12px 20px" p="0">
              <CardBody textAlign="center"> All routes are finished!</CardBody>
            </Card>
          )}
        </HStack>
      </Flex>
    </>
  )
}

const RecentUpdates = requireRoles(
  {
    requireRoles: [
      UserRole.ADMIN,
      UserRole.TECH,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_ADMIN,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
    hideIfMissing: true,
  },
  function RecentUpdates() {
    const { data: recentUpdates } = useRecentUpdates('BRIDGE')
    return (
      <>
        <Heading size="md">Recent Updates</Heading>
        <SimpleGrid
          spacing={4}
          gridAutoFlow="column"
          gridAutoColumns={['75%', '75%', '30%']}
          overflowX="scroll"
        >
          {recentUpdates?.items.map((update) => (
            <UpdatesCard key={update.id} text={update} />
          ))}
        </SimpleGrid>
      </>
    )
  },
)
