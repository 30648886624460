export enum LogEvent {
  ADD_ITEMS = 'ADD_ITEMS',
  REMOVE_ITEMS = 'REMOVE_ITEMS',
  CREATE_ORDER = 'CREATE_ORDER',
  CANCEL_ORDER = 'CANCEL_ORDER',
  CHANGE_STATE = 'CHANGE_STATE',
  UPDATE_ORDER = 'UPDATE_ORDER',
  UPDATE_ACTION = 'UPDATE_ACTION',
}

export enum OrderPickupActions {
  DEVIATE_ORDER = 'DEVIATE_ORDER',
  ITEMS_HANDOVER_COMPLETED = 'ITEMS_HANDOVER_COMPLETED',
  ITEMS_PLACED_IN_QUARANTINE = 'ITEMS_PLACED_IN_QUARANTINE',
  ITEMS_STORED = 'ITEMS_STORED',
}

export enum OrderDeliveryActions {
  DEVIATE_ORDER = 'DEVIATE_ORDER',
  ITEMS_ON_THE_ROAD = 'ITEMS_ON_THE_ROAD',
  ITEMS_HANDED_OVER = 'ITEMS_HANDED_OVER',
}

export const OrderActions = {
  ...OrderDeliveryActions,
  ...OrderPickupActions,
}
