import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { fetchListing } from '../api-client/routes/listing'

export function useListing(
  { listingId, withDeleted = false }: { listingId: string; withDeleted?: boolean },
  options?: { suspense?: boolean; enabled?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['listing', listingId],
    async () => fetchListing({ id: listingId, withDeleted }, await getAccessTokenSilently()),
    {
      enabled: true,
      useErrorBoundary: true,
      suspense: true,
      ...options,
    },
  )
}
