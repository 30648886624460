import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import type { AccountId } from '@wanda-space/types'
import { fetchListingImageDetails } from '../api-client/routes/listing'

export function useListingImages(
  { listingId, sellerAccountId }: { listingId: string; sellerAccountId: AccountId },
  options?: { suspense?: boolean; enabled?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['listing-images', listingId],
    async () =>
      fetchListingImageDetails(listingId, sellerAccountId, await getAccessTokenSilently()),
    {
      enabled: true,
      useErrorBoundary: false,
      suspense: false,
      ...options,
    },
  )
}
