import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import { getProductCategory } from '../api-client/routes/product-categories'

export function useProductCategory(
  categoryId: string,
  options?: { enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['product-category', categoryId],
    async () => {
      const token = await getAccessTokenSilently()
      return getProductCategory(categoryId, token)
    },
    options,
  )
}
