import {
  type AdminProductCategoryCreateRequestDto,
  type AdminProductCategoryUpdateRequestDto,
  type ProductCategoryResponseDto,
  productCategoryResponseDto,
} from '@wanda-space/types'
import { SortOptions, SupportedCities, SupportedCountries } from '../../consts/enums'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'

export async function getProductCategories(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ count: number; items: ProductCategoryResponseDto[] }> {
  const response = await fetchChunk({
    ...params,
    search: params.search?.trim(),
    url: '/admin/product-categories',
  })
  return { ...response, items: productCategoryResponseDto.array().parse(response.items) }
}

export async function getProductCategory(
  id: string,
  token: string,
): Promise<ProductCategoryResponseDto> {
  const response = await getProductCategories({
    orderBy: 'id',
    orderDir: 'ASC',
    itemsPerPage: 1,
    page: 0,
    search: undefined,
    filters: { id },
    token,
  })
  return productCategoryResponseDto.parse(response.items[0])
}

export async function createProductCategory(
  values: AdminProductCategoryCreateRequestDto,
  token: string,
): Promise<ProductCategoryResponseDto> {
  return productCategoryResponseDto.parse(
    await request({
      url: '/admin/product-categories/',
      method: 'POST',
      token,
      body: values,
    }),
  )
}

export async function updateProductCategory(
  id: string,
  values: AdminProductCategoryUpdateRequestDto,
  token: string,
): Promise<ProductCategoryResponseDto> {
  return productCategoryResponseDto.parse(
    await request({
      url: `/admin/product-categories/${id}`,
      method: 'PUT',
      token,
      body: values,
    }),
  )
}

export async function deleteProductCategory(id: string, token: string) {
  return await request({
    url: `/admin/product-categories/${id}`,
    method: 'DELETE',
    token,
  })
}
