import type {
  ExtraOrderlines,
  ItemDto,
  OrderDto,
  WarehouseLocationDto,
} from '@wanda-space/ops-types'
import {
  type HandoverConcludeItem,
  type HandoverDto,
  type HandoverExtraOrderlines,
  type HandoverItem,
  type HandoverPossessor,
  HandoverPossessorType,
  HandoverSourceType,
  Routine,
} from '@wanda/shared'
import { OrderType } from 'consts'
import { groupBy, prop } from 'ramda'

export enum HandoverStage {
  PICKING_AT_WAREHOUSE = 'PICKING_AT_WAREHOUSE',
  PICKING_AT_HUB = 'PICKING_AT_HUB',
  CUSTOMER_TO_DRIVER = 'CUSTOMER_TO_DRIVER',
  DRIVER_TO_WAREHOUSE = 'DRIVER_TO_WAREHOUSE',
  WAREHOUSE_TO_DRIVER = 'WAREHOUSE_TO_DRIVER',
  DRIVER_TO_CUSTOMER = 'DRIVER_TO_CUSTOMER',
  LOCATION_TO_LOCATION = 'LOCATION_TO_LOCATION',
  WAREHOUSE_TO_CUSTOMER = 'WAREHOUSE_TO_CUSTOMER',
  CUSTOMER_TO_WAREHOUSE = 'CUSTOMER_TO_WAREHOUSE',
}

export const isCustomerToDriver = (handover: HandoverDto): boolean =>
  handover.currentPossessor.type === HandoverPossessorType.CUSTOMER &&
  handover.targetPossessor.type === HandoverPossessorType.WANDA_EMPLOYEE

export const isDriverToWarehouse = (handover: HandoverDto): boolean =>
  handover.currentPossessor.type === HandoverPossessorType.WANDA_EMPLOYEE &&
  handover.targetPossessor.type === HandoverPossessorType.WAREHOUSE

export const isWarehouseToDriver = (handover: HandoverDto): boolean =>
  (handover.currentPossessor.type === HandoverPossessorType.WAREHOUSE ||
    handover.currentPossessor.type === HandoverPossessorType.WAREHOUSE_OUTBOUND) &&
  handover.targetPossessor.type === HandoverPossessorType.WANDA_EMPLOYEE

export const isDriverToCustomer = (handover: HandoverDto): boolean =>
  handover.currentPossessor.type === HandoverPossessorType.WANDA_EMPLOYEE &&
  handover.targetPossessor.type === HandoverPossessorType.CUSTOMER

export const isToWarehouse = (handover: HandoverDto): boolean =>
  handover.targetPossessor.type === HandoverPossessorType.WAREHOUSE
export const isToCustomer = (handover: HandoverDto): boolean =>
  handover.targetPossessor.type === HandoverPossessorType.CUSTOMER

export const isWarehouseToCustomer = (handover: HandoverDto) =>
  handover.currentPossessor.type === HandoverPossessorType.WAREHOUSE &&
  handover.targetPossessor.type === HandoverPossessorType.CUSTOMER

export const isCustomerToWarehouse = (handover: HandoverDto) =>
  handover.currentPossessor.type === HandoverPossessorType.CUSTOMER &&
  handover.targetPossessor.type === HandoverPossessorType.WAREHOUSE

export const isPickingAtWarehouse = (handover: HandoverDto): boolean => {
  if (handover.source.type === HandoverSourceType.ROUTINE && handover.source.id === Routine.R1) {
    return true
  }

  // old picking flow
  return (
    handover.currentPossessor.type === HandoverPossessorType.WAREHOUSE &&
    handover.targetPossessor.type === HandoverPossessorType.WAREHOUSE_OUTBOUND
  )
}

export const isPickingAtHub = (handover: HandoverDto): boolean =>
  handover.targetPossessor.type === HandoverPossessorType.ROUTE_OUTBOUND

export const isLocationToLocation = (handover: HandoverDto): boolean =>
  handover.currentPossessor.type === HandoverPossessorType.LOCATION &&
  handover.targetPossessor.type === HandoverPossessorType.LOCATION

export const parseHandoverStage = (handover: HandoverDto) => {
  if (isCustomerToDriver(handover)) {
    return HandoverStage.CUSTOMER_TO_DRIVER
  }
  if (isDriverToWarehouse(handover)) {
    return HandoverStage.DRIVER_TO_WAREHOUSE
  }
  if (isWarehouseToDriver(handover)) {
    return HandoverStage.WAREHOUSE_TO_DRIVER
  }
  if (isPickingAtWarehouse(handover)) {
    return HandoverStage.PICKING_AT_WAREHOUSE
  }
  if (isPickingAtHub(handover)) {
    return HandoverStage.PICKING_AT_HUB
  }
  if (isLocationToLocation(handover)) {
    return HandoverStage.LOCATION_TO_LOCATION
  }
  if (isDriverToCustomer(handover)) {
    return HandoverStage.DRIVER_TO_CUSTOMER
  }
  if (isWarehouseToCustomer(handover)) {
    return HandoverStage.WAREHOUSE_TO_CUSTOMER
  }
  if (isCustomerToWarehouse(handover)) {
    return HandoverStage.CUSTOMER_TO_WAREHOUSE
  }
  throw new Error('Unknown handover stage')
}
interface RoutineHandover extends HandoverDto {
  source: {
    type: HandoverSourceType.ROUTINE
    id: Routine
  }
}
export const isRoutine = (handover: HandoverDto): handover is RoutineHandover =>
  handover.source.type === HandoverSourceType.ROUTINE && handover.source.id in Routine

export const parseRoutine = (handover: RoutineHandover): Routine => {
  return Routine[handover.source.id]
}

export const formatHandoverStage = (stage: HandoverStage): string => {
  switch (stage) {
    case HandoverStage.CUSTOMER_TO_DRIVER:
      return 'Customer to Driver'
    case HandoverStage.DRIVER_TO_WAREHOUSE:
      return 'Driver to Warehouse'
    case HandoverStage.WAREHOUSE_TO_DRIVER:
      return 'Warehouse to Driver'
    case HandoverStage.DRIVER_TO_CUSTOMER:
      return 'Driver to Customer'
    case HandoverStage.PICKING_AT_WAREHOUSE:
      return 'Picking'
    case HandoverStage.LOCATION_TO_LOCATION:
      return 'Update Location'
    case HandoverStage.PICKING_AT_HUB:
      return 'Outbound'
    case HandoverStage.CUSTOMER_TO_WAREHOUSE:
      return 'Customer to warehouse'
    case HandoverStage.WAREHOUSE_TO_CUSTOMER:
      return 'Warehouse to customer'
  }
}

interface createPossessorParams {
  stage: HandoverStage
  driverId?: string
  customerId?: string
  warehouseId?: string
  fromLocationId?: string
  toLocationId?: string
  partnerId?: string
}
export const createPossessors = (
  params: createPossessorParams,
): { targetPossessor: HandoverPossessor; currentPossessor: HandoverPossessor } => {
  switch (params.stage) {
    case HandoverStage.CUSTOMER_TO_DRIVER:
      return {
        currentPossessor: { type: HandoverPossessorType.CUSTOMER, id: params.customerId! },
        targetPossessor: { type: HandoverPossessorType.WANDA_EMPLOYEE, id: params.driverId! },
      }
    case HandoverStage.DRIVER_TO_WAREHOUSE:
      return {
        currentPossessor: { type: HandoverPossessorType.WANDA_EMPLOYEE, id: params.driverId! },
        targetPossessor: { type: HandoverPossessorType.WAREHOUSE, id: params.warehouseId! },
      }
    case HandoverStage.WAREHOUSE_TO_DRIVER:
      return {
        currentPossessor: { type: HandoverPossessorType.WAREHOUSE, id: params.warehouseId! },
        targetPossessor: { type: HandoverPossessorType.WANDA_EMPLOYEE, id: params.driverId! },
      }
    case HandoverStage.DRIVER_TO_CUSTOMER:
      return {
        currentPossessor: { type: HandoverPossessorType.WANDA_EMPLOYEE, id: params.driverId! },
        targetPossessor: { type: HandoverPossessorType.CUSTOMER, id: params.customerId! },
      }
    case HandoverStage.PICKING_AT_WAREHOUSE:
      return {
        currentPossessor: { type: HandoverPossessorType.WAREHOUSE, id: params.warehouseId! },
        targetPossessor: {
          type: HandoverPossessorType.WAREHOUSE_OUTBOUND,
          id: params.toLocationId!,
        },
      }
    case HandoverStage.PICKING_AT_HUB:
      return {
        currentPossessor: { type: HandoverPossessorType.WAREHOUSE, id: params.warehouseId! },
        targetPossessor: {
          type: HandoverPossessorType.ROUTE_OUTBOUND,
          id: params.toLocationId!,
        },
      }
    case HandoverStage.LOCATION_TO_LOCATION:
      return {
        currentPossessor: { type: HandoverPossessorType.LOCATION, id: params.fromLocationId! },
        targetPossessor: { type: HandoverPossessorType.LOCATION, id: params.toLocationId! },
      }
    case HandoverStage.WAREHOUSE_TO_CUSTOMER:
      return {
        currentPossessor: { type: HandoverPossessorType.WAREHOUSE, id: params.warehouseId! },
        targetPossessor: { type: HandoverPossessorType.CUSTOMER, id: params.driverId! },
      }
    case HandoverStage.CUSTOMER_TO_WAREHOUSE:
      return {
        currentPossessor: { type: HandoverPossessorType.CUSTOMER, id: params.driverId! },
        targetPossessor: { type: HandoverPossessorType.WAREHOUSE, id: params.warehouseId! },
      }
  }
}

const requiredString = <T>(obj: T, key: keyof T) => {
  const value: T[typeof key] = obj[key]
  if (typeof value === 'string') {
    return value
  }
  throw new Error(`${key.toString()} is required in ${JSON.stringify(obj)}`)
}
export const mapItemToHandover = (
  item: Pick<ItemDto, 'id' | 'type' | 'ownerId' | 'name' | 'simpleId'>,
): HandoverItem => ({
  id: item.id,
  type: item.type,
  ownerId: requiredString(item, 'ownerId'),
  name: item.name,
  simpleId: `${item.simpleId}`,
})

export const mapItemToHandoverConcludeItem = (
  item: Pick<ItemDto, 'id' | 'type' | 'ownerId' | 'name' | 'simpleId'> & {
    comment?: string
    scanned?: boolean
  },
): HandoverConcludeItem => ({
  id: item.id,
  type: item.type,
  ownerId: requiredString(item, 'ownerId'),
  name: item.name,
  simpleId: `${item.simpleId}`,
  scanned: item.scanned,
  comment: item.comment,
})

export const mapLocationToPossessor = (location: WarehouseLocationDto): HandoverPossessor => ({
  type: HandoverPossessorType.LOCATION,
  id: location.id,
})

export const mapExtraOrderline = (
  orderlines: HandoverExtraOrderlines[],
): ExtraOrderlines | undefined => {
  if (orderlines.length === 0) {
    return undefined
  }
  const orderlineInputDto: ExtraOrderlines = orderlines.map((orderline) => ({
    itemId: orderline.item?.id,
    productId: orderline.product.id,
    quantity: orderline.quantity,
  }))

  const orderlineInputDtoPayload = orderlineInputDto.filter((orderline) => !!orderline.itemId)
  const perProductOrderlines = orderlineInputDto.filter((orderline) => !orderline.itemId)
  const orderlinesPerProductId = groupBy(prop('productId'), perProductOrderlines)

  for (const productId in orderlinesPerProductId) {
    orderlineInputDtoPayload.push({
      productId: productId,
      quantity: orderlinesPerProductId[productId].length,
    })
  }

  return orderlineInputDtoPayload
}

export const mapItemToHandoverProduct = (
  item: Pick<ItemDto, 'storageProduct'>,
): ItemDto['storageProduct'] => item.storageProduct

export const formatTimeslotAction = (type?: OrderDto['type']) => {
  switch (type) {
    case OrderType.PICK_UP:
      return 'pick up'
    case OrderType.DELIVERY:
      return 'deliver'
    default:
      return 'unknown'
  }
}
