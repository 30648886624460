import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import type { ApiFilters } from '../api-client/chunk'
import { getWarehouses } from '../api-client/routes/warehouses'

export function useWarehouses(
  params?: { search?: string; filters: ApiFilters },
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['warehouses', params],
    async () => {
      const token = await getAccessTokenSilently()
      return getWarehouses({ search: undefined, filters: undefined, ...params, token })
    },
    { staleTime: 1000 * 60 * 60, ...options },
  )
}
