import { useAuth0 } from '@auth0/auth0-react'
import type { AdminItemResponseDto, PaginatedResponseDto } from '@wanda-space/types'

import { useQuery } from '@tanstack/react-query'
import type { ApiFilters } from '../api-client/chunk'
import { fetchItems } from '../api-client/routes/items'

export function useItems(
  params: {
    orderBy: string
    orderDir: 'ASC' | 'DESC'
    itemsPerPage: number
    page: number
    search: string | undefined
    filters: ApiFilters | undefined
    withDeleted?: boolean
  },
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  const query = useQuery(
    ['items', params],
    async (): Promise<PaginatedResponseDto<AdminItemResponseDto>> => {
      const token = await getAccessTokenSilently()
      return fetchItems({ ...params, token })
    },
    { ...options, suspense: options?.suspense ?? true },
  )
  return query
}
