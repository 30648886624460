import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useProductCategory } from '../hooks/useProductCategory'

export function ProductCategory({ categoryId }: { categoryId: string }) {
  const { data, isInitialLoading } = useProductCategory(categoryId, { enabled: !!categoryId })

  if (isInitialLoading) {
    return null
  }

  if (!data) {
    return <>{categoryId}</>
  }

  return <FormattedMessage id={`${data?.localizationKey}.name`} />
}
