import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import { getWarehouses } from 'api/routes/warehouse'

export function useWarehouses(
  params: { search?: string; filters: Record<string, string[] | string | undefined> },
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['warehouses', params],
    async () => {
      const token = await getAccessTokenSilently()
      return getWarehouses({ search: undefined, ...params, token })
    },
    { staleTime: 1000 * 60 * 60, ...options },
  )
}
