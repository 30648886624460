import { useQuery } from '@tanstack/react-query'
import React, { type ReactNode } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import { getTranslations } from '@wanda/shared/src/api-client/routes/localization'

const locale = 'en'

export function IntlProvider({ children }: { children: ReactNode }) {
  const result = useQuery(
    ['translations', locale],
    async () => {
      return getTranslations(locale)
    },
    { suspense: true },
  )

  return (
    <ReactIntlProvider messages={result.data} locale={locale} defaultLocale={locale}>
      {children}
    </ReactIntlProvider>
  )
}
