import { Button, Img, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { HistoryItem, ItemEvents } from '@wanda/shared'
import { TakePhotoModal } from '@wanda/shared/src/components/TakePhotoModal/TakePhotoModal'
import { useInternalImages } from '@wanda/shared/src/hooks/useInternalImages'
import { useItemHistory } from '@wanda/shared/src/hooks/useItemHistory'
import { ListingDetails } from 'components/Listing/ListingDetails'
import { isEmpty } from 'ramda'
import React, { useState } from 'react'

import ItemDetails from 'components/ItemDetails'
import { useItem } from 'hooks/useItem'

interface Props {
  id?: string
  showUpdateLocation?: boolean
  defaultIndex?: number
}
export function ItemDetailTabs({ id, showUpdateLocation = false, defaultIndex = 0 }: Props) {
  const item = useItem(id)
  const history = useItemHistory(item.data?.id)
  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const { data: itemImages } = useInternalImages({ items: item.data?.id ? [item.data.id] : [] })

  if (!item.data) return null
  return (
    <>
      <Tabs defaultIndex={defaultIndex}>
        <TabList justifyContent="center">
          <Tab>Details</Tab>
          <Tab>Internal Images</Tab>
          <Tab>Events</Tab>
          <Tab>History</Tab>
          {item.data?.listingId && <Tab>Listing</Tab>}
        </TabList>

        <TabPanels>
          <TabPanel>
            <ItemDetails id={item.data?.id} showUpdateLocation={showUpdateLocation} />
          </TabPanel>
          {item.data && (
            <TabPanel>
              <Button marginBottom="4" width="100%" onClick={() => setShowPhotoModal(true)}>
                Take Photo
              </Button>

              {itemImages?.items.map((image) => (
                <Img marginBottom="2" key={image.id} src={image.file} />
              ))}
            </TabPanel>
          )}

          <TabPanel>
            <ItemEvents itemId={item.data.id} />
          </TabPanel>

          <TabPanel>
            {history.isSuccess && (
              <>
                <Text as="h3" fontSize="large" fontWeight="semibold" mb="2">
                  History
                </Text>
                <Stack>
                  {history.data?.diffs
                    .filter(({ diff }) => !!diff && !isEmpty(diff))
                    .map((item, index) => (
                      <HistoryItem key={index} {...item} />
                    ))}
                </Stack>
              </>
            )}
          </TabPanel>
          {item.data?.listingId ? (
            <TabPanel>
              <ListingDetails listingId={item.data?.listingId} />
            </TabPanel>
          ) : null}
        </TabPanels>
      </Tabs>
      {item.data && (
        <TakePhotoModal
          itemIds={[item.data.id]}
          handleToggleModal={setShowPhotoModal}
          open={showPhotoModal}
        />
      )}
    </>
  )
}
