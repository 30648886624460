import { createSlice } from '@reduxjs/toolkit'

interface RouteListState {
  page: number
  totalCount: number
  search: string
}
export const initialState = {
  page: 1,
  totalCount: 0,
  search: '',
} as RouteListState

const slice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
  },
})

export const { setPage, setSearch } = slice.actions
export default slice.reducer
