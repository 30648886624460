import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import type { City } from '@wanda-space/ops-types'
import type { SupportedCities } from '@wanda-space/types'

import { getWarehouseLocation } from '../api-client/routes/warehouses'
import { LocationType } from '../consts/enums'
import { useWarehouseLocations } from './useWarehouseLocations'

export function useWarehouseLocation(
  { id }: { id: string },
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['warehouse-location', id],
    async () => {
      const token = await getAccessTokenSilently()
      return getWarehouseLocation({ id, token })
    },
    { staleTime: 1000 * 60 * 60, ...options },
  )
}

export function useOutboundRouteLocation(city: SupportedCities | City) {
  const { data: hubOutboundLocations } = useWarehouseLocations({
    filters: {
      type: LocationType.OUTBOUND_ROUTE,
      city: city,
    },
  })
  return hubOutboundLocations?.[0]
}
