import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import deepDiff from 'deep-diff'
import { prop, sortBy } from 'ramda'

import { fetchItemHistory, fetchItemOpsHistory } from '../api-client/routes/items'

export function useItemHistory(itemId: string | undefined) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['item-history', itemId],
    async () => {
      const token = await getAccessTokenSilently()
      const opsHistory = sortBy(
        prop('timestamp'),
        (await fetchItemOpsHistory(itemId!, token)).items,
      )
      const opsDiffs = opsHistory.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            system: 'ops-api' as const,
            previous: null,
            diff: [],
          }
        }
        const prev = opsHistory[index - 1]
        return {
          ...item,
          system: 'ops-api' as const,
          previous: prev,
          diff: deepDiff(prev.data, item.data) ?? [],
        }
      })

      const hwHistory = sortBy(prop('timestamp'), (await fetchItemHistory(itemId!, token)).items)
      const hwDiffs = hwHistory.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            system: 'howls-castle' as const,
            previous: null,
            diff: [],
          }
        }
        const prev = hwHistory[index - 1]
        return {
          ...item,
          system: 'howls-castle' as const,
          previous: prev,
          diff: deepDiff(prev.data, item.data) ?? [],
        }
      })

      return {
        history: sortBy(prop('timestamp'), [...opsHistory, ...hwHistory]).reverse(),
        diffs: sortBy(prop('timestamp'), [...opsDiffs, ...hwDiffs]).reverse(),
      }
    },
    { enabled: !!itemId },
  )
}
