import type { ItemDto } from '@wanda-space/ops-types'
import React, { useMemo, useRef, useState } from 'react'

import { IconButton } from '@chakra-ui/react'

import { captureException } from '@sentry/react'

import { useFeatureFlags } from '@wanda/shared'

import { MdSurroundSound } from 'react-icons/md'

import itemBeep from 'assets/sounds/item-scanner-beep.mp3'

export function ScannerBeep({ item }: { item?: Pick<ItemDto, 'id' | 'name'> }) {
  const audioRef = useRef<HTMLAudioElement>(null)
  const audioContext = useRef<AudioContext | null>(new window.AudioContext())
  const itemRef = useRef<string | null>(null)
  const featureFlagsQuery = useFeatureFlags()
  const [isAudioReady, setAudioReady] = useState(audioContext.current?.state === 'running')

  const createAudioContext = async () => {
    if (itemRef.current) {
      itemRef.current = null
    }
    if (audioContext.current?.state === 'suspended') {
      await audioContext.current.resume()
    }
    if (audioContext.current?.state === 'running') {
      setAudioReady(true)
    }
    return audioContext.current
  }

  useMemo(() => {
    ;(async () => {
      if (audioContext.current?.state !== 'running' && isAudioReady) {
        setAudioReady(false)
      } else {
        await createAudioContext()
      }
    })()
  }, [audioRef.current, audioContext.current?.state])

  useMemo(() => {
    if (featureFlagsQuery.featureFlags?.ENABLE_ITEM_SCANNER_BEEP) {
      ;(async () => {
        try {
          if (audioRef.current && item && item.id !== itemRef.current) {
            await audioRef.current.play()
            itemRef.current = item.id
          }
        } catch (error) {
          captureException(error)
        }
      })()
    }
  }, [item?.id, audioRef.current, featureFlagsQuery.featureFlags])

  return (
    <>
      <audio ref={audioRef} src={itemBeep} preload="auto" />
      {featureFlagsQuery.featureFlags?.ENABLE_ITEM_SCANNER_BEEP ? (
        <IconButton
          isRound
          variant="outline"
          color={isAudioReady ? 'red.500' : undefined}
          right="4"
          zIndex={120}
          top="10"
          position="absolute"
          size="md"
          aria-label="allow-sound"
          icon={<MdSurroundSound />}
          onClick={createAudioContext}
        />
      ) : null}
    </>
  )
}
