import { propEq } from 'ramda'
import React, { useMemo } from 'react'

import { useWarehouses } from '../../hooks/useWarehouses'

export function Warehouse({ id }: { id: string }) {
  const { data: warehouses } = useWarehouses({ filters: { id } }, { suspense: true })
  const warehouse = useMemo(
    () => (warehouses?.items || []).find(propEq(id, 'id')),
    [warehouses?.items, id],
  )
  return <span data-testid="Warehouse">{warehouse ? warehouse.name : id}</span>
}
