import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import type { ApiFilters } from '../api-client/chunk'
import { fetchOrders } from '../api-client/routes/orders'

export function useOrders(
  { search, filters }: { search?: string; filters?: ApiFilters },
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['orders', search, filters],
    async () => {
      const token = await getAccessTokenSilently()
      return fetchOrders({
        search,
        page: 0,
        itemsPerPage: 50,
        filters,
        orderBy: 'createdAt',
        orderDir: 'ASC',
        token,
      })
    },
    options,
  )
}
