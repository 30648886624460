import { Badge, Box, Flex, HStack, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { sentenceCase } from 'sentence-case'

import type { ConsignmentDto } from '@wanda-space/ops-types'
import { sortBy } from 'ramda'
import { useConsignmentsForItem } from '../../hooks'
import { useHandoversForItem } from '../../hooks/useHandovers'
import { type HandoverDto, HandoverState } from '../../types/handover'
import { AccountLink } from '../AccountLink'
import { Datetime } from '../Datetime/Datetime'
import { HandoverConclusionDetails } from '../HandoverConclusion'
import { HandoverTitle } from '../HandoverTitle'

function isHandover(event: HandoverDto | ConsignmentDto): event is HandoverDto {
  return (event as HandoverDto).conclusion !== undefined
}

function HandoverBlock({ handover, itemId }: { handover: HandoverDto; itemId: string }) {
  let text = ''
  const inExpectedItems = handover.expectedItems.some((item) => item.id === itemId)
  const inItemsHandedOver = handover.conclusion?.itemsHandedOver.find((item) => item.id === itemId)

  if (inExpectedItems && inItemsHandedOver) {
    text = 'Item was handed over'
    if (inItemsHandedOver.scanned) {
      text += ' and scanned'
    } else if (inItemsHandedOver.scanned === false) {
      text += ' but not scanned'
    } else {
      text += ' but the system did not track wether it was scanned'
    }
  } else if (inExpectedItems && !inItemsHandedOver) {
    text = 'Item was not handed over, but should have been'
  } else if (!inExpectedItems && inItemsHandedOver) {
    text = 'Item was manually added in the handover'
  }
  return (
    <>
      <HandoverTitle handover={handover} />
      <Text>{text}</Text>
      <Text fontSize="sm">
        State: {sentenceCase(handover.state)} <br />
        <HandoverConclusionDetails handover={handover} />
      </Text>
    </>
  )
}

function ConsignmentBlock({ consignment }: { consignment: ConsignmentDto }) {
  return (
    <VStack alignItems="stretch" gap={1}>
      <Text>
        Item - Consignment{' '}
        <Text as="span" fontWeight="semibold">
          #{consignment.simpleId}
        </Text>
      </Text>
      <HStack>
        <Text>Consignment Items: </Text>
        {consignment.numDuoItems ? <Badge>DUO {consignment.numDuoItems}</Badge> : null}
        {consignment.numSoloItems ? <Badge>SOLO {consignment.numSoloItems}</Badge> : null}
      </HStack>
      <Text fontSize="sm">
        Added by {consignment.createdBy ? <AccountLink id={consignment.createdBy} /> : 'Unknown'}
      </Text>
    </VStack>
  )
}

export function ItemEvents({ itemId }: { itemId: string }) {
  const { data } = useHandoversForItem(itemId)
  const { data: consignments } = useConsignmentsForItem(itemId)
  const handovers = data?.filter(
    (handover) =>
      handover.state === HandoverState.CONCLUDED &&
      (handover.source.type !== 'ROUTINE' ||
        handover.conclusion?.itemsHandedOver.some((item) => item.id === itemId)),
  )

  const handoverBlockBg = useColorModeValue('gray.200', 'gray.700')
  const consignmentBd = useColorModeValue('blackAlpha.50', 'whiteAlpha.300')
  const events = sortBy(
    (event) => {
      const date = (event as HandoverDto).conclusion?.concludedAt ?? event.createdAt ?? new Date(0)
      return date
    },
    [...(handovers ?? []), ...(consignments ?? [])],
  )
  return (
    <Stack spacing="4">
      {events.map((event) => {
        const dateReference = isHandover(event) ? event.conclusion?.concludedAt : event.createdAt
        return (
          <Box
            key={event.id}
            background={isHandover(event) ? handoverBlockBg : consignmentBd}
            borderRadius="8px"
            p="2"
          >
            <Flex alignItems="center">
              <Box flex="1" padding={2}>
                <Text fontSize="small">
                  <Datetime showTime value={dateReference} />
                </Text>
                {isHandover(event) ? (
                  <HandoverBlock itemId={itemId} handover={event as HandoverDto} />
                ) : (
                  <ConsignmentBlock consignment={event} />
                )}
              </Box>
            </Flex>
          </Box>
        )
      })}
    </Stack>
  )
}
