import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import type { ApiFilters } from '../api-client/chunk'
import {
  fetchHandovers,
  fetchHandoversByItem,
  fetchHandoversByOrder,
} from '../api-client/routes/handovers'

export function useHandoversForOrder(orderId: string) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['handovers-for-order', orderId],
    async () =>
      (
        await fetchHandoversByOrder({
          orderId,
          token: await getAccessTokenSilently(),
        })
      ).items,
  )
}

export function useHandoversForItem(itemId: string) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['handovers-for-item', itemId],
    async () =>
      (
        await fetchHandoversByItem({
          itemId,
          token: await getAccessTokenSilently(),
        })
      ).items,
    { enabled: !!itemId, suspense: true },
  )
}

export function useHandovers(
  filters?: ApiFilters,
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery(
    ['handovers'],
    async () =>
      (
        await fetchHandovers({
          page: 0,
          itemsPerPage: 1000,
          orderBy: 'id',
          orderDir: 'ASC',
          filters,
          search: undefined,
          token: await getAccessTokenSilently(),
        })
      ).items,
    options,
  )
}
