import { Select } from '@chakra-ui/react'
import React from 'react'

import Loader from 'components/ui/Loader'
import type { SupportedCities, SupportedCountries } from 'consts'
import { useWarehouses } from 'hooks/useWarehouses'

interface Props {
  onChange: (id: string) => void
  city?: SupportedCities
  countryCode?: SupportedCountries
  disabled?: boolean
  value?: string
}
const WarehouseSelector = ({ onChange, city, countryCode, disabled, value }: Props) => {
  const { data, isInitialLoading, status } = useWarehouses({
    filters: { city, country: countryCode },
  })
  if (isInitialLoading) return <Loader size="xl" />
  if (status === 'error' || !data) throw new Error('unable to fetch warehouses')

  return (
    <Select
      w="100%"
      isDisabled={!!disabled}
      name="warehouses"
      onChange={(event) => onChange(event.target.value as string)}
      value={value}
    >
      <option value="">---</option>
      {data.items.map((warehouse, i) => (
        <option value={warehouse.id} key={i}>
          {warehouse.name}
        </option>
      ))}
    </Select>
  )
}

export default WarehouseSelector
