import {
  type EntityState,
  type PayloadAction,
  type SerializedError,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  nanoid,
} from '@reduxjs/toolkit'
import type { ConsignmentDto, ConsignmentInputDto } from '@wanda-space/ops-types'

import {
  createConsignment as createConsignmentEndpoint,
  fetchConsignment as fetchConsignmentEndpoint,
} from '@wanda/shared/src/api-client/routes/consignments'

type ConsignmentState =
  | {
      id: string
      selectedItemIds: string[]
      source: ConsignmentInputDto['source']
      consignment?: never
    }
  | { id: string; consignment: ConsignmentDto; source?: never; selectedItemIds?: never }

export type State = {
  submitting: boolean
  error: SerializedError | undefined
  activeConsignment: EntityState<ConsignmentState, string>
}

const consignmentAdapter = createEntityAdapter<ConsignmentState>()

export const initialState: State = {
  submitting: false,
  error: undefined,
  activeConsignment: consignmentAdapter.getInitialState(),
}

export const createConsignment = createAsyncThunk(
  'consignment/createConsignment',
  async (data: { body: ConsignmentInputDto; token: string }) => {
    return await createConsignmentEndpoint(data)
  },
)

export const createConsignmentStatePayload = ({
  itemIds,
  source = { type: 'MANUAL' },
}: { itemIds: ConsignmentInputDto['itemIds']; source?: ConsignmentInputDto['source'] }) => ({
  id: nanoid(),
  itemIds,
  source,
})

export const fetchConsignment = createAsyncThunk(
  'consignment/fetchConsignment',
  async ({ id, token }: { id: string; token: string }) => {
    return await fetchConsignmentEndpoint({ id, token })
  },
)

export const slice = createSlice({
  name: 'consignment',
  initialState,
  reducers: {
    createConsignmentState: (
      state,
      { payload }: PayloadAction<{ id: string } & ConsignmentInputDto>,
    ) => {
      consignmentAdapter.addOne(state.activeConsignment, {
        id: payload.id,
        selectedItemIds: payload.itemIds,
        source: payload.source,
      })
    },
    removeConsignmentState: (state, { payload }: PayloadAction<{ id: string }>) => {
      consignmentAdapter.removeOne(state.activeConsignment, payload.id)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createConsignment.pending, (state) => {
      state.submitting = true
      state.error = undefined
    })
    builder.addCase(createConsignment.fulfilled, (state, { payload }) => {
      state.submitting = false
      state.error = undefined
      consignmentAdapter.addOne(state.activeConsignment, { id: payload.id, consignment: payload })
    })
    builder.addCase(createConsignment.rejected, (state, action) => {
      state.submitting = false
      state.error = action.error
    })
    builder.addCase(fetchConsignment.pending, (state) => {
      state.submitting = false
      state.error = undefined
    })
    builder.addCase(fetchConsignment.fulfilled, (state, { payload }) => {
      state.submitting = false
      state.error = undefined
      consignmentAdapter.addOne(state.activeConsignment, { id: payload.id, consignment: payload })
    })
  },
})

export const consignmentSelector = consignmentAdapter.getSelectors<{ consignment: State }>(
  (state) => state.consignment.activeConsignment,
)
export default slice.reducer
export const { createConsignmentState, removeConsignmentState } = slice.actions
