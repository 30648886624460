import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'

import { uploadInternalImage } from '../../api-client/routes/images'
import { uploadItemImage } from '../../api-client/routes/items'
import { INTERNAL_IMAGE_QUERY_KEY } from '../../hooks/useInternalImages'
import ItemImage from './ItemImage'
import UploadButton from './UploadButton'

// Either handleSubmit is defined or handoverId and/or itemIds are defined.
// Prevents uploading image without associating a resource
type Props =
  | {
      open: boolean
      handleToggleModal: (value: boolean) => void
      handleSubmit: ({ file, imgsrc }: { file: File; imgsrc: string }) => void
      handoverId?: undefined
      itemIds?: undefined
    }
  | {
      open: boolean
      handleToggleModal: (value: boolean) => void
      handoverId?: undefined
      itemIds: string[]
      handleSubmit?: undefined
    }
  | {
      open: boolean
      handleToggleModal: (value: boolean) => void
      handoverId: string
      itemIds?: undefined
      handleSubmit?: undefined
    }
  | {
      open: boolean
      handleToggleModal: (value: boolean) => void
      handoverId: string
      itemIds: string[]
      handleSubmit?: undefined
    }

export function TakePhotoModal({
  handleToggleModal,
  open,
  handleSubmit,
  handoverId,
  itemIds,
}: Props) {
  const [imgsrc, setImgsrc] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const toast = useToast()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async ({ file }: { file: File }) => {
      if (!(handoverId || itemIds)) {
        throw new Error('handoverId or itemIds must be provided')
      }
      const token = await getAccessTokenSilently()
      const form = new FormData()
      form.append('file', file)
      const uploads: Promise<any>[] = [
        uploadInternalImage({
          handoverId: handoverId!,
          file,
          itemIds: itemIds!,
          token,
        }),
      ]
      if (itemIds && itemIds.length === 1) {
        uploads.push(uploadItemImage({ itemId: itemIds[0], form, token }))
      }
      await Promise.all(uploads)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          INTERNAL_IMAGE_QUERY_KEY,
          { items: itemIds, handover: handoverId },
        ])
        toast({
          title: 'Image uploaded successfully',
          status: 'success',
        })
        handleToggleModal(false)
      },
      onError: (error: any) => {
        toast({
          title: 'Failed upload image for item',
          description: error.message,
          status: 'error',
        })
        captureException(error)
      },
    },
  )

  const handleConfirm = handleSubmit ?? (() => mutation.mutate({ file: file! }))

  return (
    <Modal
      isOpen={open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => handleToggleModal(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Take a photo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box height="200px">
            <ItemImage src={imgsrc} />
          </Box>
          <UploadButton
            id="image"
            name="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { files } = e.target
              if (files instanceof FileList && files.length > 0) {
                setFile(files[0])
                setImgsrc(URL.createObjectURL(files[0]))
              }
            }}
          >
            {imgsrc ? 'Retake photo' : 'Take photo'}
          </UploadButton>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            autoFocus
            style={{ marginRight: '8px' }}
            onClick={() => handleToggleModal(false)}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            isDisabled={!file || !imgsrc || mutation.isLoading}
            isLoading={mutation.isLoading}
            onClick={() => handleConfirm({ file: file!, imgsrc })}
            variant="solid"
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
