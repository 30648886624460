import type {
  ConsignmentDto,
  ConsignmentInputDto,
  ConsignmentItemLocationUpdateDto,
  WarehouseLocationDto,
} from '@wanda-space/ops-types'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import { opsBaseUrl } from '../config'

export const createConsignment = async ({
  token,
  body,
}: { token: string; body: ConsignmentInputDto }) => {
  return await request<ConsignmentDto>({
    method: 'POST',
    url: '/v1/consignments/',
    token,
    body: body,
    baseUrl: opsBaseUrl,
  })
}

export const fetchConsignment = async ({ token, id }: { token: string; id: string }) => {
  return await request<ConsignmentDto>({
    method: 'GET',
    url: `/v1/consignments/${id}/`,
    baseUrl: opsBaseUrl,
    token,
  })
}

export const fetchConsignments = async (params: {
  orderBy?: string
  orderDir?: 'ASC' | 'DESC'
  itemsPerPage?: number
  page?: number
  search?: string | undefined
  filters?: ApiFilters | undefined
  token: string
}) => {
  return await fetchChunk<ConsignmentDto>({
    baseUrl: opsBaseUrl,
    url: '/v1/consignments/',
    orderBy: 'simpleId',
    orderDir: 'ASC',
    page: params.page || 1,
    itemsPerPage: params.itemsPerPage || 100,
    filters: undefined,
    ...params,
    search: params.search?.trim(),
  })
}

export const updateItemLocationByConsignmentId = async ({
  id,
  token,
  body,
}: { id: string; token: string; body: ConsignmentItemLocationUpdateDto }) => {
  return await request<{ consignment: ConsignmentDto; location: WarehouseLocationDto }>({
    method: 'POST',
    baseUrl: opsBaseUrl,
    url: `/v1/consignments/${id}/location/`,
    token,
    body,
  })
}

export const fetchConsignmentsByItem = async ({
  itemId,
  token,
}: {
  itemId: string
  token: string
}) => {
  const response = await request<ConsignmentDto[]>({
    method: 'GET',
    token,
    baseUrl: opsBaseUrl,
    url: `/v1/consignments/by-item/${itemId}/`,
  })

  if (Array.isArray(response)) {
    return { items: response, count: response.length }
  }
  return response
}

export const fetchConsignmentByLocationId = async ({
  locationId,
  token,
}: {
  locationId: string
  token: string
}) => {
  const response = await request<ConsignmentDto[]>({
    method: 'GET',
    token,
    baseUrl: opsBaseUrl,
    url: `/v1/consignments/by-location/${locationId}/`,
  })

  if (Array.isArray(response)) {
    return { items: response, count: response.length }
  }
  return response
}
