import { Link } from '@chakra-ui/react'
import React from 'react'

export function TechSupportLink() {
  return (
    <Link href="https://wandadotspace.slack.com/channels/tech-support" isExternal>
      #tech-support
    </Link>
  )
}
