import { request } from '@wanda/shared/src/api-client/api-client'

import { SortDirection } from 'utils/sortUtil'

import type { OrderModel, OrderUpdateModel } from '../../interface'

export const fetchOrder = async ({ token, id }: { token: string; id: string }) => {
  return await request<OrderModel>({
    method: 'GET',
    url: `/admin/order/${id}`,
    token,
  })
}

export const fetchOrders = async ({
  token,
  page,
  itemsPerPage,
  search,
  filters,
}: {
  token: string
  page?: number
  itemsPerPage?: number
  filters?: Record<string, string | string[] | undefined>
  search?: string
}) => {
  const params = new URLSearchParams({
    orderBy: 'orderDetails.deliveryDate',
    orderDir: SortDirection.DESC,
    page: page ? page.toString() : '0',
    itemsPerPage: itemsPerPage ? itemsPerPage.toString() : '10',
  })
  if (search && /^#\d+$/.test(search)) {
    params.append('filters', JSON.stringify({ ...filters, simpleId: search.replace('#', '') }))
  } else {
    if (filters) {
      params.append('filters', JSON.stringify(filters))
    }
    if (search) {
      params.append('search', search)
    }
  }
  return await request<{ count: number; items: OrderModel[] }>({
    method: 'GET',
    url: '/admin/order',
    params,
    token,
  })
}

/** @deprecated old flow */
export const updateOrder = async ({ order, token }: { order: OrderUpdateModel; token: string }) => {
  return await request({
    method: 'PUT',
    url: `/admin/order/${order.id}`,
    token,
    body: {
      state: order.state,
      storageItemIds: order.storageItemIds,
      orderDetails: order.orderDetails,
      internalComment: order.internalComment,
    },
  })
}
export const fulfillOrder = async ({
  orderId,
  token,
}: {
  orderId: string
  token: string
}): Promise<OrderModel> => {
  return request({
    method: 'POST',
    url: `/admin/order/${orderId}/fulfill`,
    token,
    body: {},
  })
}

export const rejectOrder = async ({
  orderId,
  token,
}: {
  orderId: string
  token: string
}): Promise<OrderModel> => {
  return request({
    method: 'POST',
    url: `/admin/order/${orderId}/reject`,
    token,
    body: {},
  })
}
