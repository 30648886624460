import { captureException } from '@sentry/react'
import { format } from 'date-fns'
import React from 'react'

export function Datetime(props: {
  value: Date | string | number | undefined | null
  showTime?: boolean
  showDate?: boolean
}) {
  if (!props.value) {
    return null
  }
  let value: Date
  try {
    value = new Date(props.value)
    if (typeof props.value === 'string') {
      if (props.value.length === 13 && props.value.match(/^\d+$/)) {
        value = new Date(Number.parseInt(props.value))
      } else {
        value = new Date(props.value)
      }
    }

    // Trigger validation of the date
    value.toISOString()
  } catch (error) {
    captureException(error, { extra: { value: props.value } })
    return <span title={props.value.toString()}>Invalid Date</span>
  }

  let formatString = ''
  const { showDate = true, showTime = false } = props

  if (showDate !== false) {
    if (showTime) {
      formatString = 'dd.MM.yyyy HH:mm'
    } else {
      formatString = 'dd.MM.yyyy'
    }
  } else {
    if (showTime) {
      formatString = 'HH:mm'
    } else {
      throw new Error('At least one of showDate or showTime must be true')
    }
  }

  return <span title={value.toISOString()}>{format(value, formatString)}</span>
}
