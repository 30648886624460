import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { rejectOrder as rejectOrderEndpoint } from 'api/routes/orders'
import type { OrderType } from 'consts'

interface OrderListState {
  page: number
  search: string
  showOnlyActiveOrders: boolean
  orderType: OrderType | undefined
}
export const initialState = {
  page: 1,
  search: '',
  showOnlyActiveOrders: true,
  orderType: undefined,
} as OrderListState

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
    setShowOnlyActiveOrders: (state, action) => {
      state.showOnlyActiveOrders = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setOrderTypeFilter: (state, action) => {
      state.orderType = action.payload
    },
  },
})

export const rejectOrder = createAsyncThunk(
  'order/rejectOrder',
  async ({ orderId, token }: { orderId: string; token: string }) => {
    return rejectOrderEndpoint({ orderId, token })
  },
)

export const { setPage, setSearch, setShowOnlyActiveOrders, setOrderTypeFilter } = slice.actions
export default slice.reducer
