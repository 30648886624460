export enum FormType {
  ReportItemIssue = '1eM2R_zyZyR2v-fxzkHeEgp-O-AexfAdVQJ3aBCT1imk',
  OpsDriversReport = '1wjqMGsY3ktRdWzamjGJlL8LULc-_wTrbFIj8F_KA5g0',
}

export type Fields = {
  [FormType.ReportItemIssue]: {
    id: string | undefined
    ownerId: string | undefined
  }
  [FormType.OpsDriversReport]: {
    routeId: string | undefined
    serviceArea: string | undefined
  }
}

const fieldMap: Fields = {
  [FormType.ReportItemIssue]: {
    id: 'entry.119081040',
    ownerId: 'entry.508606874',
  } as const,
  [FormType.OpsDriversReport]: {
    routeId: 'entry.1739609935',
    serviceArea: 'entry.59101679',
  } as const,
} as const

export function createGoogleFormLink<Form extends FormType>(form: Form, fields: Fields[Form]) {
  const params = new URLSearchParams()
  for (const key in fields) {
    const value = fields[key]
    if (value) {
      // @ts-ignore ts does not understand
      params.append(fieldMap[form][key], value)
    }
  }
  return `https://docs.google.com/forms/d/${form}/viewform?${params.toString()}`
}
