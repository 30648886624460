import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
} from '@chakra-ui/react'
import type { Diff } from 'deep-diff'
import React from 'react'

import { UserRole, useRoles } from '../../hooks'
import { Actor } from '../Actor/Actor'
import { Datetime } from '../Datetime/Datetime'
import { DiffList, isHiddenField } from './DiffList'

type HistoryItemProps<T> = {
  actor: string
  diff: Diff<T, T>[]
  timestamp: string
  data: T
  system: 'howls-castle' | 'ops-api'
  showHiddenFields?: boolean
  previous: {
    actor: string
    timestamp: string
    data: T
  } | null
}

export function HistoryItem({
  actor,
  system,
  diff,
  timestamp,
  previous,
  data,
  showHiddenFields = false,
}: HistoryItemProps<unknown>) {
  const [showDetails, setShowDetails] = React.useState(false)
  const roles = useRoles()
  const hasVisibleFields = diff.filter((diff) => !isHiddenField(diff.path, system)).length > 0
  if (!hasVisibleFields) {
    return null
  }

  return (
    <Box p="2" border="1px solid" borderColor="gray.200" borderRadius="8px">
      <Flex justifyContent="space-between" alignItems="baseline">
        <Datetime showTime value={timestamp} />
        <Box maxW="60%" overflowX="scroll">
          <HStack maxW="200%">
            <Actor actor={actor} />
          </HStack>
        </Box>
      </Flex>
      <DiffList diffs={diff} showHiddenFields={showHiddenFields} system="ops-api" />
      <Flex mt="4" justifyContent="space-between" alignItems="baseline">
        {roles.includes(UserRole.TECH) ? <Text>{system}</Text> : <span />}
        <Button variant="link" onClick={() => setShowDetails(true)} size="sm" colorScheme="gray">
          More info
        </Button>
      </Flex>
      <Modal isOpen={showDetails} onClose={() => setShowDetails(false)} size="full">
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>History details</ModalHeader>
          <Text as="h3" fontSize="x-large" fontWeight="semibold" pt="4">
            Diff:
          </Text>
          <DiffList diffs={diff} showHiddenFields system="ops-api" />
          <ModalBody>
            <Text as="h3" fontSize="x-large" fontWeight="semibold" pt="4">
              Previous:
            </Text>
            <pre>{JSON.stringify(previous, null, 3)}</pre>
            <Text as="h3" fontSize="x-large" fontWeight="semibold" pt="4">
              Current:
            </Text>
            <pre>{JSON.stringify({ timestamp, actor, data }, null, 2)}</pre>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
