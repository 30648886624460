import type { ItemDto } from '@wanda-space/ops-types'
import type { HandoverItem } from '@wanda/shared'

import { pick } from 'ramda'

import type { AdminItemResponseDto } from '@wanda-space/types'
import type { ItemSubset } from './itemLookupHistory'

export const toHandoverItem = (item: AdminItemResponseDto | ItemDto): HandoverItem => ({
  id: item.id,
  type: item.type,
  name: item.name,
  simpleId: `${item.simpleId}`,
  ownerId: item.ownerId!,
})
export const toItemSubset = (item: ItemDto): ItemSubset => pick(['id', 'simpleId', 'name'], item)
