import {
  Box,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import type QrScanner from 'qr-scanner'
import React, { useCallback } from 'react'
import { MdClose, MdHistory } from 'react-icons/md'
import { validate } from 'uuid'

import { useAppSelector } from 'hooks/useAppSelector'

import type { InboundItemDto, ItemDto } from '@wanda-space/ops-types'
import { ScannerBeep } from './Handover/ScannerBeep'
import { Scanner } from './QrScanner/QrScanner'

type Props = {
  onScan: (id: string) => void | Promise<void>
  onError: (error: Error) => void
  onInputChange: (value: string) => void
  id: string | undefined
  item?: ItemDto | InboundItemDto
  uuid?: boolean
  enableHistory?: boolean
}
export const ItemScanner = ({
  onScan,
  onError,
  onInputChange,
  id,
  enableHistory,
  uuid = true,
  item,
}: Props) => {
  const itemHistory = useAppSelector((state) => state.itemLookupHistory.items)
  const handleScan = useCallback(
    (result: QrScanner.ScanResult) => {
      if (uuid) {
        if (validate(result.data)) {
          onScan(result.data)
        }
      } else {
        onScan(result.data)
      }
    },
    [onScan],
  )
  return (
    <Box position="relative" w="400px" maxW="100%" margin="0 auto">
      <ScannerBeep item={item} />
      <Scanner onScan={handleScan} onError={onError} />
      <HStack
        w="100%"
        p="4"
        pt="1"
        pb="1"
        position="absolute"
        bottom="0"
        backgroundColor="whiteAlpha.600"
      >
        {enableHistory ? (
          <Menu>
            <MenuButton
              isDisabled={itemHistory.length === 0}
              fontSize="1.5rem"
              as={IconButton}
              aria-label="item-lookup-history"
              icon={<MdHistory />}
              variant="outline"
            />
            <MenuList maxW="90vw" maxH="calc(100vh - 360px)" overflowY="scroll">
              {itemHistory.map((item) => (
                <MenuItem onClick={() => onInputChange(item.id)} key={item.id}>
                  #{item.simpleId} - {item.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : null}

        <Input
          name="id"
          value={id ?? ''}
          placeholder='Item id or simple ID like "#1234"'
          onChange={(event) => onInputChange(event.target.value)}
          variant="flushed"
          autoComplete="off"
          _placeholder={{ color: 'gray.900' }}
        />
        {id && (
          <IconButton
            position="absolute"
            size="xs"
            variant="outline"
            right="0.5"
            top="0.5"
            icon={<MdClose />}
            aria-label="close"
            onClick={() => onInputChange('')}
          />
        )}
      </HStack>
    </Box>
  )
}
