import type { AccountId } from '@wanda-space/types'
import type { Product } from '@wanda/shared/src/api-client/routes/products'

import type {
  DeliveryTimeSlots,
  OrderState,
  OrderType,
  StorageItemType,
  SupportedCities,
  SupportedCountries,
  TransportStatus,
  TransportType,
} from 'consts'

export interface ItemShipmentDetailsDto {
  /** Delivery date */
  shipmentDate: string
  /** Delivery time slot */
  shipmentTimeSlots: DeliveryTimeSlots
  /** Extra delivery info */
  extraShipmentInfo: string | undefined
  /** Is delivery contactless */
  isContactless: boolean
  /** Id of the order, it is filled only when there is any ongoing shipment */
  currentOrderId: string
  /** Status of the shipment item, it is filled only when there is any ongoing shipment */
  shipmentStatus: TransportStatus | undefined
  /** Type of the shipment, it is filled only when there is any ongoing shipment */
  shipmentType: TransportType | undefined
}

export interface ContactInfoDto {
  /** First name */
  firstName: string
  /** Last name */
  lastName: string
  /** Name for B2B customer */
  companyName?: string
  /** Billing contact info */
  address: OrderAddressDto
  /** Email */
  email: string
  /** Mobile phone number */
  mobilePhone: string
  /** Order contact person */
  contactPerson?: ContactPerson
}

export interface LocationDto {
  /** Latitude */
  lat: number
  /** Longitude */
  lng: number
}

export interface OrderAddressDto {
  /** Street */
  street: string
  /** City */
  city: string
  /** Postal code */
  postalCode: string
  /** Country code, usually two chars */
  countryCode: string
}

export interface OrderDetailsDto {
  /** Delivery date */
  deliveryDate: string
  /** Delivery time slot */
  deliveryTimeSlot: { from: string; to: string }
  /** Contact info */
  contactInfo: ContactInfoDto
  /** User locale */
  locale?: string
  /** Is delivery contactless */
  isContactless?: boolean
  /** Extra delivery info */
  extraDeliveryInfo?: string
  /** Coupon */
  coupon?: string
  /** Items count confirmation */
  countConfirmed?: boolean
  /** Reason for a deviation */
  deviateReason?: string
  /** Reason for a removal */
  removeReason?: string
  /** Taas service level */
  serviceLevelType?: ServiceLevelType
  /** floor number */
  floorNumber?: number
  /** Elevator */
  elevator?: boolean
  /** If storage is less than 3 months */
  shortStorage?: boolean
}
export interface OrderLine {
  product: Product
  quantity: number
  userId?: string
  item?: {
    id: string
    type: StorageItemType
    name: string
  }
}

export interface OrderModel {
  id: string
  type: OrderType
  state: OrderState
  accountId: AccountId
  simpleId: string
  createdAt: string
  updatedAt: string
  orderDetails: OrderDetailsDto
  storageItems: any[]
  city: SupportedCities
  countryCode: SupportedCountries
  serviceOrderId?: string
  listingId?: string
  orderLines: OrderLine[]
}

export interface OrderDetailsModel {
  id: string
  type: OrderType
  state: OrderState
  simpleId: string
  storageItemIds: string[]
  orderDetails: OrderDetailsDto
}

export interface OrderUpdateModel {
  id: string
  state: OrderState
  storageItemIds: string[]
  orderDetails: OrderDetailsDto
  internalComment: string
}
export interface OrderDetailsUpdate {
  id: string
  orderDetails: OrderDetailsDto
}
export enum ServiceLevelType {
  CURBSIDE = 'CURBSIDE',
  CARRYING = 'CARRYING',
  FIRST_DOOR = 'FIRST_DOOR',
}

export interface ContactPerson {
  name: string
  phoneNumber: string
}
