import { isAccountId } from '@wanda-space/types'
import React from 'react'
import type { HandoverDto } from '../types/handover'
import { AccountLink } from './AccountLink'
import { Datetime } from './Datetime/Datetime'
import { UserLink } from './UserLink'

export function HandoverConclusionDetails({ handover }: { handover: HandoverDto }) {
  return handover.conclusion ? (
    <>
      Concluded by{' '}
      {isAccountId(handover.conclusion.concludedBy) ? (
        <AccountLink id={handover.conclusion.concludedBy} />
      ) : (
        <UserLink id={handover.conclusion.concludedBy} />
      )}{' '}
      at <Datetime value={handover.conclusion.concludedAt} showTime /> with{' '}
      {handover.conclusion.itemsHandedOver?.length}/{handover.expectedItems.length} items
    </>
  ) : null
}
